@charset "utf-8";
// FORM COMPONENTS
.page-form{
	.first-text{
		margin: 50px auto 65px;
	}

	.form-block{
		margin-bottom: 180px;
		@include max-screen(767px){
			margin-bottom: 85px;
		}
	}

	.table-form{
		text-align: left;
		@include max-screen(767px){
			display: block;
			width: 100%;
		}
		tbody, tr{
			@include max-screen(767px){
				display: block;
			}
		}
		tr{
			border-bottom: 1px solid $white;
			@include max-screen(767px){
				border-bottom: none;
			}
			&:last-child{
				border-bottom: none;
			}
		}
		td,
		th{
			vertical-align: top;
			padding: 12px 0;
			padding-left: 25px;
			@include max-screen(767px){
				display: block;
				padding: 10px 4%;
			}
		}

		td{
			background-color: $color19;
			@include setftRe(12,0.08em,25px);
		}

		th{
			background-color: $color28;
			@include setftRe(14,0.08em,25px);
			font-weight: bold;
			width: 350px;
			@include max-screen(767px){
				width: 100%;
			}

			.is-required{
				@extend .ffKozukaGothic;
				color: $white;
				background-color: $color03;
				@include setftRe(10,0.1em,18px);
				font-weight: bold;
				display: inline-block;
				padding: 0 4px;
				vertical-align: middle;
				margin-bottom: 2px;
			}
		}

		.form-input-list{
			li{
				display: inline-block;
				vertical-align: middle;
				@include setftRe(14,0.08em,25px);
				padding-right: 35px;
				@include max-screen(767px){
					padding-right: 20px;
				}
			}
		}

		.address-table{
			tr{
				border-bottom: none;
			}
			th,
			td{
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 0;
				vertical-align: middle;
				@include max-screen(767px){
					display: block;
					padding: 5px 0;
				}
			}
			th{
				width: 150px;
				background-color: $color19;
				@include setftRe(12,0.08em,25px);
				font-weight: normal;
				@include max-screen(767px){
					width: 100%;
					padding-bottom: 0;
				}
			}
			td{
				@include max-screen(767px){
					padding-top: 0;
				}
			}
		}

		.form-control{
			height: 23px;
		}

		.form-text,
		.form-select,
		.form-textarea{
			border: 1px solid $color16;
			padding: 0 7px;
			@include setftRe(12,0.08em,21px);
			vertical-align: middle;
		}

		.form-text{
			width: 60%;
			@include border-radius(5px);
			@include max-screen(1023px){
				width: 90%;
			}
			@include max-screen(767px){
				width: 100%;
			}
		}

		.form-checkbox{
			-webkit-appearance: radio;
		}
		.form-radio{
			-webkit-appearance: radio;
		}
		.form-checkbox,
		.form-radio{
			vertical-align: middle;
			margin-bottom: 3px;
		}

		.form-select{
			width: 150px;
			background: url('../img/koza_apply/ico_selectarrow.svg') right 5px center no-repeat;
			background-size: 7px 14px;
			background-color: $white;
			padding-right: 17px;
			@include border-radius(3px);
			@include max-screen(767px){
				width: 100%;
			}
		}

		.form-zipcode{
			width: 110px;
			margin-left: 5px;
			@include max-screen(767px){
				width: 35%;
			}
		}

		.form-zipcode-button{
			@include setftRe(12,0.08em,21px);
			font-weight: bold;
			@include border-radius(0);
			background-color: $color03;
			color: $white;
			vertical-align: middle;
			margin-left: 10px;
			width: 160px;
			text-align: center;
			border: none;
			@include max-screen(767px){
				width: 45%;
			}
		}

		.form-pref-select{
			width: 180px;
			@include max-screen(767px){
				width: 60%;
			}
		}

		.form-note{
			margin-left: 5px;
			vertical-align: middle;
			@include max-screen(1023px){
				display: block;
			}
		}
		.form-textarea{
			-webkit-appearance: none;
			width: 85.5%;
			@include border-radius(10px);
			min-height: 222px;
			margin-bottom: 10px;
			-webkit-appearance: none;
			box-shadow: none;
			@include max-screen(767px){
				width: 100%;
				min-height: 130px;
				margin-bottom: 5px;
			}
		}
	}

	.step02{
		margin-top: 100px;
		@include max-screen(767px){
			margin-top: 50px;
		}
		.table-form{
			td,
			th{
				@include setftRe(14,0.08em,25px);
				padding-right: 25px;
			}
			.address-table{
				th,
				td{
					vertical-align: top;
					padding-top: 0;
					padding-right: 0;
				}
				th{
					@include max-screen(767px){
						padding-top: 10px;
					}
				}
				tr:last-child{
					th,
					td{
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.checkbox-confirm{
		margin-top: 80px;
		background-color: $color19;
		padding: 28px 20px 27px;
		@include max-screen(767px){
			padding: 15px 15px 12px;
			margin-top: 40px;
		}
		input[type="checkbox"]{
			-webkit-appearance: checkbox;
			vertical-align: middle;
			margin-bottom: 3px;
			@include max-screen(767px){
				display: inline-block;
				vertical-align: top;
				margin-bottom: 0;
				margin-top: 5px;
			}
		}
		label{
			@include setftRe(14,0.08em,25px);
			margin-left: 5px;
			@include max-screen(767px){
				@include setftRe(12,0.08em,21px);
				display: inline-block;
				vertical-align: top;
				text-align: left;
				width: 90%;
				margin-left: 3px;
			}
		}
	}

	.btn-group{
		margin-top: 36px;
		font-size: 0;
		.form-btn{
			@include setftRe(14,0.08em,25px);
			font-weight: bold;
			display: inline-block;
			vertical-align: middle;
			padding: 10px 0;
			width: 320px;
			border: none;
			@include max-screen(767px){
				width: 100%;
			}
		}
		.btn-to-confirm-form{
			@include transition_c(0.3s);
			cursor: pointer;
			&:hover{
				@include min-screen(1025px) {
					opacity: 0.5;
				}
			}
		}
		.btn-back{
			margin-left: 30px;
			@include max-screen(767px){
				margin-left: 0;
				margin-top: 12px;
			}
		}
	}

	&.page-form-thanks{
		.main-content{
			text-align: center;
			.large-text{
				color: $color03;
				@include setftRe(16,0.08em,28px);
			}
			.text{
				@include setftRe(14,0.08em,21px);
				@include max-screen(767px){
					font-size: 12px;
					margin-top: 5px;
				}
			}
			.to-top-btn{
				@include setftRe(14,0.08em,21px);
				width: 320px;
				margin: 40px auto 0;
				padding: 12px 0 13px;
				@include max-screen(767px){
					width: 100%;
					margin-top: 20px;
				}
			}
		}
	}
}


