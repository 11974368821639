.blog{
	.main-img .page-name-box .jpn{
		color: $black;
	}
	.full-screen-sp{
		@include max-screen(767px) {
			padding: 0;
		}
	}
	.main-content{
		padding: 100px 0 140px;
		@include max-screen(767px) {
			padding: 10px 0 65px;
		}
	}
	.related-block{
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 40px;
		}
	}
	.content-left{
		float: left;
		padding-top: 3px;
		width: 66%;
		text-align: left;
		@include max-screen(767px) {
			float: none;
			padding-top: 0;
			width: 100%;
		}
		.box{
			overflow: hidden;
			width: 100%;
			border-bottom: 1px solid #d9d9d9;
			padding-bottom: 30px;
			margin-bottom: 30px;
		}
		.frame .box:last-child{
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
		.box .thumb{
			float: left;
			width: 34%;
			overflow: hidden;
		}
		.box .thumb img{
			width: 100%;
			height: auto;
			-webkit-transition: 0.2s all;
			-moz-transition: 0.2s all;
			-ms-transition: 0.2s all;
			transition: 0.2s all;
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
		}
		.box .thumb:hover img{
			-webkit-transform: scale(1.1);
			-moz-transform: scale(1.1);
			-ms-transform: scale(1.1);
			transform: scale(1.1);
		}
		.box .text{
			float: left;
			width: 66%;
			padding-left: 20px;
		}
		.box .text ul{
			margin-bottom: 15px;
		}
		.box .text ul li{
			float: left;
		}
		.box .text ul li.date{
			font-size: 15px;
			color: #666666;
			margin-right: 10px;
		}
		.box .text ul li.cat a{
			color: $white;
		}
		.box .text dl{
			margin-bottom: 10px;
		}
		.box .text dl dt{
			margin-bottom: 5px;
			font-weight: bold;
		}
		.box .text dl dt a{
			font-size: 16px;
			color: #666;
			line-height: 1.5;
		}
		.box .text dl dd{
			font-size: 16px;
			line-height: 1.5;
			color: #333333;
		}
		.box .text dl dd a{
			font-size: 14px;
			color: #b2b2b2;
		}
		.box .text .author{
			font-size: 14px;
			color: #666;
		}

		// sp
		@media only screen and (max-width: 767px) {
			.box{
				padding: 15px !important;
				margin-bottom: 0;
			}
			.box .text{
				padding-left: 15px;
			}
			.box .text ul{
				margin-bottom: 5px;
			}
			.box .text ul li.date{
				font-size: 12px;
			}
			.box .text ul li.cat a{
				font-size: 11px;
			}
			.box .text dl dt a{
				font-size: 13px;
				margin-bottom: 0;
			}
			.box .text dl dd{
				display: none
			}
			.box .text .author{
				font-size: 12px;
			}
		}

		// detail
		&.detail{
			color: $color01;
			line-height: 1.6;
			@include max-screen(767px){
				margin-bottom: 30px;
				padding-left: 20px;
				padding-right: 20px;
			}
			div#ez-toc-container{
				width: 100%;
				a:visited,
				a{
					color: $color01;
				}
			}
			b, strong {
				font-size: 110%;
			}
			ul.topinfo{
				margin-bottom: 15px;
			}
			ul.topinfo li{
				float: left;
			}
			ul.topinfo li.date{
				font-size: 15px;
				color: #666666;
				margin-right: 10px;
			}
			ul.topinfo li.cat a{
				display: block;
				float: left;
				font-size: 12px;
				color: #fff;
				text-align: center;
				background: #d34192;
				padding: 2px 5px;
				text-decoration: none;
				margin-right: 5px;
			}
			.frame .author{
				clear: both;
				font-size: 14px;
				margin-bottom: 10px;
			}
			.frame .thumb{
				margin-bottom: 20px;
			}
			.frame .thumb img{
				width: 100%;
				height: auto;
			}
			.frame .title{
				font-size: 26px;
				font-weight: bold;
				letter-spacing: 1px;
				margin-bottom: 30px;
			}
			.frame > div img{
				max-width: 100%;
				height: auto;
			}
			.frame > div p{
				font-size: 16px;
				line-height: 1.8em;
			}
			.frame > div h1{
				font-size: 20px;
				border-top: 1px solid #333333;
				border-bottom: 1px solid #333333;
				padding: 10px 0;
				margin: 15px 0;
				letter-spacing: 1px;
				text-align: left;
				font-weight: bold;
			}
			.frame > div h2{
				font-size: 20px;
				letter-spacing: 1px;
				text-align: left;
				margin: 15px 0;
				padding: 5px 0 5px 12px !important;
				border-left: 6px solid #d34192;
				border-bottom: none;
				font-weight: bold;
			}
			.frame > div h3{
				font-size: 20px;
				border-bottom: 1px solid #d9d9d9;
				border-left: none;
				padding: 0 0 10px;
				margin: 15px 0;
				text-align: left;
				letter-spacing: 1px;
				font-weight: bold;
			}
			.frame > div blockquote{
				font-size: 14px;
				background-color: #F7F7F7;
				padding: 30px 30px 20px 30px;
				border-radius: 10px;
				margin: 15px 0;
				position: relative;
			}
			.frame > div blockquote:before{
				content: "“";
				font-size: 300%;
				line-height: 1em;
				font-family: "ＭＳ Ｐゴシック",sans-serif;
				position: absolute;
				left: 10px;
				top: 10px;
			}
			.frame > div ul{
				list-style: disc;
				font-size: 16px;
				padding-left: 15px;
			}
			.frame > div ol{
				list-style: decimal;
				font-size: 14px;
				padding-left: 17px;
			}
			.frame > div .alignleft {
				float: left;
			}
			.frame ul.snsShare{
				width: 100%;
				overflow: hidden;
				margin: 50px 0;
			}
			.frame ul.snsShare li a{
				display: block;
				float: left;
				width: 33.333333%;
				text-align: center;
				padding: 15px 0;
				text-decoration: none;
			}
			.frame ul.snsShare li a span{
				font-size: 14px;
				color: #fff;
				letter-spacing: 1px;
				font-weight: bold;
				position: relative;
			}
			.frame ul.snsShare li.line a{
				background: #28b94a;
			}
			.frame ul.snsShare li.line a span:before{
				content: "";
				background: url(../img/blog/ic_line.png) no-repeat left top;
				background-size: 14px;
				width: 14px;
				height: 13px;
				position: absolute;
				top: 0;
				left: -25px;
			}
			.frame ul.snsShare li.fb a{
				background: #1961ac;
			}
			.frame ul.snsShare li.fb a span:before{
				content: "";
				background: url(../img/blog/ic_facebook.png) no-repeat left top;
				background-size: 8px;
				width: 8px;
				height: 14px;
				position: absolute;
				top: -2px;
				left: -15px;
			}
			.frame ul.snsShare li.tw a{
				background: #00b0ff;
			}
			.frame ul.snsShare li.tw a span:before{
				content: "";
				background: url(../img/blog/ic_twitter.png) no-repeat left top;
				background-size: 14px;
				width: 14px;
				height: 12px;
				position: absolute;
				top: 0;
				left: -25px;
			}
			@include max-screen(767px){
				ul.topinfo li.date{
					font-size: 12px;
				}
				ul.topinfo li.cat a{
					font-size: 11px;
				}
				.frame .author{
					font-size: 12px;
				}
				.frame .title{
					font-size: 18px;
				}
				.frame > div h1{
					font-size: 16px;
				}
				.frame > div h2{
					font-size: 16px;
				}
				.frame > div h3{
					font-size: 16px;
				}
				.frame ul.snsShare{
					margin: 30px 0;
					padding-right: 0;
				}
				.frame ul.snsShare li a{
					float: none;
					width: 100%;
					padding: 10px 0;
					margin-bottom: 10px;
				}
			}

			// .yarppBase{
			// 	.inner{
			// 		overflow: hidden;
			// 	}
			// 	.inner .box{
			// 		overflow: hidden;
			// 		float: left;
			// 		width: 48%;
			// 		border-bottom: 1px solid #d9d9d9;
			// 		margin-bottom: 15px;
			// 		padding-bottom: 15px;
			// 	}
			// 	.inner .box:nth-child(2n){
			// 		margin-left: 4%;
			// 	}
			// 	.inner .box .thumb{
			// 		float: left;
			// 		width: 34%;
			// 		overflow: hidden;
			// 	}
			// 	.inner .box .thumb img{
			// 		width: 100%;
			// 		height: auto;
			// 		-webkit-transition: 0.2s all;
			// 		-moz-transition: 0.2s all;
			// 		-ms-transition: 0.2s all;
			// 		transition: 0.2s all;
			// 		-webkit-transform: scale(1);
			// 		-moz-transform: scale(1);
			// 		-ms-transform: scale(1);
			// 		transform: scale(1);
			// 	}
			// 	.inner .box .thumb:hover img{
			// 		-webkit-transform: scale(1.1);
			// 		-moz-transform: scale(1.1);
			// 		-ms-transform: scale(1.1);
			// 		transform: scale(1.1);
			// 	}
			// 	.inner .box dl{
			// 		float: left;
			// 		width: 66%;
			// 		padding-left: 15px;
			// 	}
			// 	.inner .box dl dt{
			// 		font-size: 13px;
			// 		color: #666666;
			// 		font-weight: normal;
			// 		margin-bottom: 3px;
			// 	}
			// 	.inner .box dl dd a{
			// 		font-size: 13px;
			// 		color: #666666;
			// 		font-weight: bold;
			// 	}
			// }
		}
	}

	.content-right{
		float: right;
		padding-left: 30px;
		width: 34%;
		text-align: left;
		@include max-screen(767px) {
			float: none;
			padding-left: 20px;
			padding-right: 20px;
			width: 100%;
			margin-top: 40px;
		}
		h2{
			margin-bottom: 0;
		}
		h3{
			font-size: 17px;
			background: #fff;
			color: #666666;
			border-left: 8px solid #d34192;
			padding: 10px 0 10px 15px;
			margin-bottom: 20px;
			font-weight: bold;
			letter-spacing: 2px;
		}
		
		.search {
			margin-bottom: 50px;
			.searchPost {
				margin-top: 25px;
			}
			.btn-search {
				position: relative;
				input {
					border: 1px solid $color16;
					padding-left: 5px;
					padding-right: 40px;
					border-radius: 0px;
					@include setwh(100%,34px);
					@include max-screen(767px) {
						height: 25px;
					}
				}
				.btnSubmit {
					background-color: transparent;
					padding: 0;
					border: none;
					cursor: pointer;
					position: absolute;
					top: 0;
					right: 0;
					@include setwh(40px,100%);
					i {
						position: absolute;
						top: 0;
						right: 0;
						display: block;
						@include setwh(40px,100%);
						&::after, &::before {
							content: '';
							position: absolute;
						}
						&::before {
							border-radius: 50%;
							border: 2px solid $color03;
							right: 15px;
							top: 9px;
							@include setwh(13px,13px);
							@include max-screen(767px) {
								top: 4px;
								right: 13px;
							}
						}
						&::after {
							background-color: $color03;
							@include transform_c(rotate(-45deg));
							bottom: 7px;
							right: 14px;
							@include setwh(2px,9px);
							@include max-screen(767px) {
								bottom: 4px;
								right: 12px;
							}
						}
					}
				}
			}
		}
		.new{
			margin-bottom: 50px;
		}
		.new > div{
			overflow: hidden;
			border-bottom: 1px solid #d9d9d9;
			margin-bottom: 15px;
			padding-bottom: 15px;
		}
		.new .thumb{
			float: left;
			width: 34%;
			overflow: hidden;
		}
		.new .thumb img{
			width: 100%;
			height: auto;
			-webkit-transition: 0.2s all;
			-moz-transition: 0.2s all;
			-ms-transition: 0.2s all;
			transition: 0.2s all;
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
		}
		.new .thumb:hover img{
			-webkit-transform: scale(1.1);
			-moz-transform: scale(1.1);
			-ms-transform: scale(1.1);
			transform: scale(1.1);
		}
		.new dl{
			float: left;
			width: 66%;
			padding-left: 15px;
		}
		.new dl dt{
			font-size: 13px;
			color: #666666;
			font-weight: normal;
			margin-bottom: 8px;
		}
		.new dl dd a{
			font-size: 13px;
			line-height: 1.5;
			color: #666666;
			font-weight: bold;
		}
		.new .more a{
			display: block;
			text-decoration: underline;
			text-align: right;
		}
		.new .more a span{
			font-size: 14px;
			color: #4d4d4d;
			position: relative;
		}
		.new .more a span:before{
			content: "";
			background: url(../img/common/icon/ic_arrowGreen.png) no-repeat left top;
			background-size: 12px;
			width: 12px;
			height: 8px;
			position: absolute;
			left: -18px;
			top: 2px;
			-webkit-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
		.list{
			margin-bottom: 50px;
		}
		.list ul li{
			line-height: 1.6em;
		}
		.list ul li a{
			/*display: block;*/
			font-size: 14px;
			color: #4d4d4d;
			margin-bottom: 15px;
			padding-left: 15px;
			position: relative;
		}
		.list ul li a:before{
			content: "▶";
			font-size: 10px;
			line-height: 10px;
			color: #d34192;
			position: absolute;
			top: 0;
			left: 0;
		}
		.list ul li a.koCat{
			padding-left: 25px;
		}
		.list ul li a.koCat:before{
			content: "▶";
			font-size: 10px;
			color: #C7C7C7;
			position: absolute;
			top: 0;
			left: 10px;
		}
		.list ul.accordion li span{
			display: block;
			font-size: 14px;
			color: #4d4d4d;
			margin-bottom: 15px;
			padding-left: 15px;
			position: relative;
			cursor: pointer;
		}
		.list ul.accordion ul li{
			padding-left: 15px;
		}
		.list ul.accordion ul li:before{
			content: "▶";
			font-size: 10px;
			color: #C7C7C7;
			position: absolute;
			top: 0;
			left: 10px;
		}
		.list ul.accordion li span:before{
			content: "▶";
			font-size: 10px;
			color: #d34192;
			position: absolute;
			top: 0;
			left: 0;
		}
		.moreBtn a{
			background: #fff;
		}

		.list .menuAr {
			>li {
				position: relative;
				@include setftRe(14,0.05em,28px);
				>ul {
					display: none;
					padding-left: 15px;
					li a:before{
						display: none;
					}
				}
			}
		}

		@include max-screen(767px) {
			.new{
				margin-bottom: 30px;
			}
			.new dl dt{
				font-size: 12px;
			}
			.new .more a span{
				font-size: 13px;
			}
			.list{
				margin-bottom: 30px;
			}
		}
	}
	
	.wp-pagenavi {
		margin-top: 50px;
		@include max-screen(767px) {
			margin: 30px 0 113px;
		}
		.pages{
			display: none;
		}
		.previouspostslink, .nextpostslink {
			border: 2px solid $black;
			display: inline-block;
			position: relative;
			@include setwh(30px,40px);
			&::before {
				border-top: 4.75px solid transparent;
				border-bottom: 4.75px solid transparent;
				content: '';
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
			}
		}
		.previouspostslink {
			margin-right: 25px;
			@include max-screen(767px) {
				margin-right: 25px;
			}
			&::before {
				border-right: 6.5px solid $black;
			}
		}
		.nextpostslink {
			margin-left: 25px;
			@include max-screen(767px) {
				margin-left: 25px;
			}
			&::before {
				border-left: 6.5px solid $black;
			}
		}
		a, span {
			color: $black;
			font-weight: bold;
			padding: 0 8px;
			vertical-align: middle;
			@include font-sizeRe(14);
			@include max-screen(767px) {
				padding: 0 7px;
				@include font-sizeRe(12);
			}
		}
	}
}