@charset 'UTF-8';

.page404 {
	.main-content{
		margin: 100px 0 120px;
		@include max-screen(767px) {
			margin: 50px 0 60px;
		}
	}

	.page404-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 56px;
		}
	}

	.main-content{
		text-align: center;
		.large-text{
			color: $color03;
			@include setftRe(16,0.08em,28px);
			font-weight: bold;
		}
		.text{
			@include setftRe(14,0.08em,21px);
			margin-top: 10px;
			@include max-screen(767px){
				font-size: 12px;
				margin-top: 5px;
			}
		}
		.to-top-btn{
			@include setftRe(14,0.08em,21px);
			width: 350px;
			margin: 20px auto 0;
			padding: 12px 0 13px;
			@include max-screen(767px){
				width: 100%;
			}
		}
	}
}