@charset 'UTF-8';

.faq {
	.main-img {
	    background-image: url('../img/sakura-faq/faq/mainimg.jpg');
	    @include max-screen(767px) {
	    	background-image: url('../img/sakura-faq/faq/mainimg_sp.jpg');
	    }
	}

	.main-content{
		margin: 50px 0 165px;
		@include max-screen(767px) {
			margin: 0 0 70px;
		}
	}

	.faq-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 41px;
		}
	}
	.anchor-list{
		@include max-screen(767px) {
			margin-top: 18px;
		}
		li{
			float: left;
			width: 24%;
			margin-left: 1.3%;
			margin-top: 18px;
			@include max-screen(767px) {
				margin: 0;
				margin-top: 6px;
				width: 100%;
			}
			&:nth-child(4n+1){
				margin-left: 0;
				clear: left;
			}
			&:nth-child(-n+4){
				margin-top: 0;
				@include max-screen(767px) {
					margin-top: 6px;
				}
			}
			&:first-child{
				@include max-screen(767px) {
					margin-top: 0;
				}
			}
			a{
				font-size: 12px;
				padding: 9px 0 8px;
				@include max-screen(1129px) {
					background-position: 10px center;
				}
				@include screen(768px, 1023px) {
					background-position: 14px center;
					display: table;
					width: 100%;
					padding-left: 25px;
					padding-right: 25px;
					span{
						display: table-cell;
						vertical-align: middle;
					}
				}
				@include max-screen(767px) {
					font-weight: bold;
				}
			}
		}
	}

	.anchor-block{
		margin-top: 80px;
		@include max-screen(767px){
			margin-top: 32px;
		}

		.titleCommon{
			@include max-screen(767px){
				padding-bottom: 6px;
			}
		}
	}

	.faq-grid{
		padding-top: 32px;
		@include max-screen(767px){
			padding-top: 18px;
		}
		// .masonry-item,
		// .masonry-sizer{
		// 	width: 48.85%;
		// }
		// .masonry-gutter{
		// 	width: 2.3%;
		// }

		.col{
			width: 48.85%;
			@include max-screen(767px){
				width: 100%;
			}

			&.right{
				.faq-item{

					&:first-child{
						@include max-screen(767px){
							margin-top: 18px;
						}
					}
				}
			}
		}

		.faq-item{
			width: 100%;
			margin-top: 42px;
			text-align: left;
			padding-bottom: 20px;
			border-bottom: 1px solid $color16;
			@include max-screen(767px){
				margin-top: 18px;
				padding-bottom: 15px;
			}
			&:first-child{
				margin-top: 0;
			}
			.title{
				@include setftRe(16,0.08em,28px);
				font-weight: bold;
				background-color: $color01;
				color: $white;
				padding: 7px 10px;
				@include max-screen(767px){
					@include setftRe(14,0.08em,21px);
					padding: 9px 10px;
				}
			}

			.content{
				margin-top: 20px;
				@include max-screen(767px){
					margin-top: 5px;
					padding-left: 0;
				}
				&.has-pl{
					padding-left: 10px;
					@include max-screen(767px){
						padding-left: 0;
					}
				}
				p{
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px){
						@include setftRe(12,0.08em,18px);
					}
					span{
						color: $color03;
						font-weight: bold;
					}
					a{
						color: $color03;
						text-decoration: underline;
					}
				}
				.links-list{
					a{
						color: $color03;
						text-decoration: underline;
						@include setftRe(14,0.08em,25px);
						@include max-screen(767px){
							@include setftRe(12,0.08em,18px);
						}
					}
				}
			}
		}
	}
}