@charset "utf-8";


.page-form {
	&.contact {
		.btn-group {
		    margin: 64px 0 130px;
		    @include max-screen(767px) {
		    	margin: 35px 0 110px;
		    }
		    .form-btn {
		    	padding: 12px 0;
		    }
		}
		.table-form{
			.form-input-item{
				@include max-screen(767px) {
					display: block;
				}
			}
			.form-textarea.shorter{
				min-height: 75px;
				@include max-screen(767px) {
					min-height: 50px;
				}
			}
		}
	}

	&.page-form-thanks{
		.main-content{
			margin: 70px 0 150px;
			@include max-screen(767px) {
				margin: 25px 0 48px;
			}
		}
	}
}
.contact {
	.main-img {
	    background-image: url(../img/contact/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/contact/mainimg_sp.jpg);
	    }
	}
	&__textTop {
		text-align: justify;
		p {
			@include setftRe(14,0.08em,25px);
			&.fz12 {
				@include max-screen(767px) {
					@include setftRe(12,0.08em,21px);
				}
			}
			&.textCenter {
			    margin: 45px 0 37px;
				text-align: center;
				@include max-screen(767px) {
				    margin: 25px 0 30px;
					text-align: justify;
				}
			}
			&.textPink {
				color: $color03;
				font-weight: bold;
				margin-top: 25px;
			}
		}
		.telPink {
		    margin: 28px 0 63px;
		    @include max-screen(767px) {
		    	margin: 25px 0 50px;
		    }
			a {
				color: $color03;
				font-weight: bold;
				text-decoration: underline;
				@include setftRe(24,0.08em,25px);
			}
			img {
				padding-right: 6px;
				vertical-align: middle;
			}
		}
		&__block01 {
			padding-bottom: 75px;
			@include max-screen(767px) {
				padding-bottom: 43px;
			}
			.titleCommon.fZize28 {
				@include max-screen(767px) {
					line-height: 22px;
				}
			}
		}
		&__block02 {
			padding-bottom: 30px;
			@include max-screen(767px) {
				padding-bottom: 5px;
			}
			.titleCommon {
				margin-bottom: 25px;
				@include max-screen(767px) {
					margin-bottom: 15px;
				}
			}
		}
		&__block03 {
			.titleCommon {
				margin-bottom: 30px;
				@include max-screen(767px) {
					margin-bottom: 10px;
				}
			}
		}
	}
	.formBlock {
		margin-top: 25px;
	}
	.related-block {
		padding-bottom: 150px;
		@include max-screen(767px) {
			padding-bottom: 40px;
		}
	}
}
#contact {
	textarea { overflow: auto; }
	.table-form {
		tr {
			td {
				input {
					@media screen\0{
						padding: 1px 7px 7px;
					}
				}
				select {
					@media screen\0{
						padding: 4px 5px;
					}
				}
			}
		}
	}
}
