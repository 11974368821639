@charset 'UTF-8';


/*-----------------------------------------------------------
Header
-------------------------------------------------------------*/
.header {
	border-top: 3px solid $color03;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    @include transform_c(translateZ(0));
    @include transition_c(0.4s);
    background-color: $white;
	&.act {
		@include max-screen(767px) {
			height: 100%;
			max-height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			padding-bottom: 50px;
		}
	}
	&__top {
		padding: 10px 0 17px;
		@include max-screen(767px) {
			padding: 13px 0 10px;
		}
	}


	&__h1 {
		color: $color02;
		text-align: justify;
		@include setftRe(10,0.08em,18px);
	}


	&__mid {
		margin-top: 15px;
		@include max-screen(767px) {
			margin-top: 0;
		}
	}


	&__logo {
		float: left;
		@include max-screen(965px) {
			float: none;
			margin-bottom: 30px;
		}
		@include max-screen(767px) {
			float: left;
			margin-bottom: 0;
			width: 100%;
		}
	}

	.logo-img {
		@include max-screen(767px) {
			float: left;
		}
	}
	.mobile-toggle {
	    float: right;
	    position: relative;
	    width: 33px;
	    &::after {
			bottom: -15px;
			content: 'MENU';
			font-weight: bold;
			left: 50%;
			position: absolute;
			transform: translate(-50%,0);
			@include setftRe(8,0.08em,13px);
		}
	    .heightmenu {
	    	height: 23px;
	    }
	    span {
		    transition: 0.4s;
		    &::before {
		    	background-color: $black;
			    content: '';
			    left: 0;
			    position: absolute;
			    transition: 0.4s;
			    @include setwh(32px,3px);
			}
			&:nth-child(1)::before {
			    top: 2px;
			}
			&:nth-child(2)::before {
			    top: 11px;
			}
			&:nth-child(3)::before {
			    top: 19px;
			}
		}
		&.act {
			&::after {
				content: 'CLOSE';
			}
			span {
				&:nth-child(1) {
					&::before {
						margin-top: 3px;
					    -webkit-transform: rotate(-45deg) translate(-3px, 3px);
					    transform: rotate(-45deg) translate(-3px, 3px);
					}
				}
				&:nth-child(2)::before {
				    background: none;
				}
				&:nth-child(3) {
					&::before {
						margin-top: 3px;
					    -webkit-transform: rotate(45deg) translate(-8px, -9px);
					    transform: rotate(45deg) translate(-8px, -9px);
					}
				}
			}
		}
	}


	&__right {
		float: right;
		@include max-screen(965px) {
			float: none;
			margin: 0 -15px;
		}
	}


	&__search, &__btnBox {
		display: inline-block;
		vertical-align: top;
		@include max-screen(767px) {
			display: block;
		}
	}


	&__search {
		margin: 10px 8px 0 0;
		@include max-screen(1024px) {
			margin: 10px 3px 0 0;
		}
		@include max-screen(767px) {
			margin: 10px 15px;
		}
		.btn-search {
			position: relative;
			width: 175px;
			@include max-screen(767px) {
				width: 100%;
			}
		}
		input {
			font-weight: bold;
			height: 30px;
			padding-left: 15px;
			width: 100%;
			@include setftRe(12,0.08em,21px);
			@include max-screen(767px) {
				height: 35px;
			}
		}
		.btnSubmit {
			background: url(../img/common/icon/ico_search.svg) center center no-repeat;
			background-size: 12px 12px;
			border: none;
			cursor: pointer;
			position: absolute;
			top: 0;
			right: 0;
			@include setwh(40px,100%)
			@include max-screen(767px) {
				background-size: 15px 15px;
				right: 1px;
			}
		}
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: $color09;
		}
		::-moz-placeholder { /* Firefox 19+ */
			color: $color09;
		}
		:-ms-input-placeholder { /* IE 10+ */
			color: $color09;
		}
		:-moz-placeholder { /* Firefox 18- */
			color: $color09;
		}
	}
	&__search, input {
		border: none;
		background-color: $color08;
	}


	&__btnBox {
		font-size: 0;
		padding-right: 2px;
		@include max-screen(767px) {
			padding-right: 0;
		}
		.btn {
			display: inline-block;
			margin-left: 18px;
			@include max-screen(1024px) {
				margin-left: 5px;
			}
			@include max-screen(767px) {
				margin: 0;
				width: 50%;
			}
			.btnBox {
				padding: 12px 0 14px;
				@include setftRe(14,0.08em,25px);
				@include max-screen(767px) {
				    padding: 8px 0 11px;
					@include setftRe(11,0.08em,19px);
				}
			}
		}
	}
	&__nav {
		background-color: $white;
		@include max-screen(767px) {
			display: none;
		}
	}
}



