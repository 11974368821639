@charset 'UTF-8';

.glossary {
	.main-img {
	    background-image: url(../img/services/glossary/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/services/glossary/mainimgsp.jpg);
	    }
	}
	.main-content {
		padding: 44px 0 72px;
		@include max-screen(767px) {
			padding: 23px 0 40px;
		}
	}
	.tableL {
		font-size: 0;
		li {
			display: inline-block;
			font-weight: 500;
			margin-right: percentage(10/1130);
			margin-bottom: 7px;
			vertical-align: top;
			width: percentage(104/1130);
			@include max-screen(767px) {
				margin-bottom: 7.5px;
			}
			&:nth-child(10n) {
				margin-right: 0;
			}
			a {
				display: block;
				font-weight: bold;
				padding: 4px 0;
				@include setftRe(14,0.08em,25px);
				@include max-screen(767px) {
					@include setftRe(10,0.08em,20px);
				}
			}
			&.kana_search, &.kana_search_2 {
				a {
					border: 1px solid $color01;
				}
			}
			&.kana_search_no {
				background-color: $color01;
				height: 35px;
				@include max-screen(767px) {
					height: 30px;
				}
			}
		}
	}
	.contentLan {
		text-align: justify;
	}
	.kana_midashi {
		margin-top: 37px;
		margin-bottom: 10px;
		@include max-screen(767px) {
			margin-top: 21px;
			margin-bottom: 0;
			padding-bottom: 6px;
		}
	}
	.word {
		border-bottom: 1px solid $color16;
		padding: 22px 0 26px;
		@include max-screen(767px) {
			padding: 18px 0 22px;
		}
		.midashi {
			font-weight: bold;
			@include setftRe(18,0.08em,32px);
			@include max-screen(767px) {
				@include setftRe(14,0.08em,25px);
			}
			.word-title, .kana_space {
				display: inline-block;
				vertical-align: top;
			}
		}
		.point_pt1, .point_pt2 {
			@include setftRe(14,0.08em,25px);
			@include max-screen(767px) {
				@include setftRe(12,0.08em,21px);
			}
		}
	}
	.related-block {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 40px;
		}
	}
}












