@charset 'UTF-8';

.cfd {
	.main-img {
	    background-image: url(../img/services/cfd/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/services/cfd/mainimg_sp.jpg);
	    }
	}

	.main-content {
		margin: 50px 0 135px;
		@include max-screen(767px) {
			margin: 22px 0 55px;
		}
	}

	.textTop {
		text-align: justify;
		p {
			margin-bottom: 25px;
			@include setftRe(14,0.08em,25px);
			@include max-screen(767px) {
				margin-bottom: 20px;
				@include setftRe(12,0.08em,21px);
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.sub-title {
		@include max-screen(767px) {
			font-size: 14px;
			padding: 6px 14px 5px;
		}
	}

	.anchor-list {
		margin: 53px 0 80px;
		@include max-screen(767px) {
			margin: 15px 0 28px;
		}
		li {
			float: left;
			width: percentage(214/1130);
			margin-left: percentage(15/1130);
			@include max-screen(767px) {
				margin: 0;
				width: 50%;

			}
			&:first-child {
				margin-left: 0;
			}

			&:nth-child(even) {
				a {
					@include max-screen(767px) {
						border-right: none;
						border-left: none;
					}
				}
			}
			&:nth-child(odd) {
				a {
					@include max-screen(767px) {
						border-left: 0;
					}
				}
			}
			&:nth-child(-n+2) {
				a {
					@include max-screen(767px) {
						border-top: 1px solid $color01;
					}
				}
			}
			&:last-child {
				a {
					@include max-screen(767px) {
						background-image: none;
						min-height: 35px;
					}
				}
			}
			a {
				font-size: 12px;
				padding: 11px 0;
				@include max-screen(767px) {
					border-top: none;
					padding: 6.5px 0 6.5px 28px;
					text-align: left;
					@include setftRe(10,0.08em,21px);
				}
			}
		}
	}


	.table{
		&:nth-of-type(even){
			margin-top: 60px;
			@include max-screen(767px) {
				margin-top: 25px;
			}
		}

		td,
		th{
			vertical-align: middle;
		}

		th{
			@include max-screen(767px) {
				display: none;
			}
		}
		td{
			@include max-screen(767px) {
				display: block;
			}
			p {
				padding: 11px 1px 10px;
				@include max-screen(767px) {
					padding: 8px 1px;
				}
			}
		}
		.table-heading,
		td{
			p{
				@include max-screen(767px) {
					display: inline-block;
					letter-spacing: 0;
					padding-left: 15px;
					position: relative;
					vertical-align: middle;
					@include calc-css(width, '100% - 100px');
				}
				&:before{
					@include max-screen(767px) {
						background-color: rgba($color03, 0.2);
						content: "";
						left: -100px;
						position: absolute;
						top: 0;
						@include setwh(100px,100%);
					}
				}
			}
			&:before{
				@include max-screen(767px) {
					content: attr(data-heading);
					display: inline-block;
					font-weight: bold;
					padding: 0 8px;
					vertical-align: middle;
					width: 100px;
					@include setftRe(12, 0em, 18px);
				}
			}
		}
		.sub-title {
			@include max-screen(767px) {
				margin-bottom: 15px;
			}
		}
	}


	.anchor-block {
		.after-table-note {
		    color: $color02;
		    margin-top: 15px;
		    text-align: left;
		    @include setftRe(10,0.08em,18px);
		}
		&__block01 {
			margin-bottom: 105px;
			@include max-screen(767px) {
				margin-bottom: 40px;
			}
			.titleCommon {
			    margin-bottom: 38px;
			    @include max-screen(767px) {
			    	margin-bottom: 20px;
			    }
			}
			.table {
				th {
					padding: 0 9px;
				}
				td {
					padding: 0 12px;
					text-align: left;
					@include max-screen(767px) {
						padding: 0;
					}
				}
			}
		}
		&__block02 {
			margin-bottom: 125px;
			@include max-screen(767px) {
				margin-bottom: 45px;
			}
			.titleCommon {
				margin-bottom: 24px;
				@include max-screen(767px) {
					margin-bottom: 15px;
				}
			}
			.content-block {
				text-align: justify;
			}
			.textTop {
				margin-bottom: 33px;
				@include max-screen(767px) {
					margin-bottom: 20px;
				}
			}

			.hidden-table-cover{
				margin-top: 40px;
				display: none;
				@include max-screen(767px) {
					margin-top: 20px;
				}
			}

			.special-table{
				width: 48%;
				margin-top: 0;
				@include max-screen(767px) {
					width: 100%;
				}
			}

			.table-left{
				float: left;
				@include max-screen(767px) {
					float: none;
				}
				td{
					&:first-child{
						width: 70%;
						@include max-screen(767px) {
							width: 100%;
						}
					}
				}
			}

			.table-right{
				float: right;
				@include max-screen(767px) {
					float: none;
					margin-top: 30px;
				}
			}
		}
		&__block03 {
			margin-bottom: 120px;
			@include max-screen(767px) {
				margin-bottom: 30px;
			}
			.tableWrap {
				font-size: 0;
				&__item {
					display: inline-block;
					margin-right: percentage(30/1130);
					width: percentage(550/1130);
					vertical-align: top;
					@include max-screen(767px) {
						margin-right: 0;
						margin-bottom: 30px;
						width: 100%;
					}
					&:nth-child(2n) {
						margin-right: 0;
					}
					tr {
						td {
							padding: 0 13px;
							text-align: justify;
							&:first-child {
								width: 165px;
								@include max-screen(767px) {
									width: auto;
								}
							}
							@include max-screen(767px) {
								padding: 0;
							}
						}
					}
					th,td {
						vertical-align: top;
					}
				}
			}
			.titleCommon {
				margin-bottom: 27px;
				@include max-screen(767px) {
					margin-bottom: 10px;
				}
			}
			.textTop {
				margin-bottom: 37px;
				@include max-screen(767px) {
					margin-bottom: 16px;
				}
			}
		}
		&__block04 {
			margin-bottom: 100px;
			text-align: justify;
			@include max-screen(767px) {
				margin-bottom: 45px;
			}
		}
		&__block05 {
			text-align: justify;
			.sub-title {
				margin-bottom: 14px;
			}
			.blockItem {
				.list {
					&--big {
						margin-bottom: 65px;
						@include max-screen(767px) {
							margin-bottom: 45px;
						}
					}
					&--small {
						margin-bottom: 45px;
						@include max-screen(767px) {
							margin-bottom: 40px;
						}
					}
					&__text {
						@include setftRe(14,0.08em,25px);
						@include max-screen(767px) {
							@include setftRe(12,0.08em,21px);
						}
						a {
							color: $color03;
							display: inline-block;
							padding-bottom: 2px;
							&.arrow {
								margin-bottom: 7px;
								padding-left: 15px;
								position: relative;
								&::before {
									background: url(../img/services/cfd/ico_pink.svg) no-repeat;
									content: '';
									left: 2px;
									position: absolute;
									top: 50%;
									margin-top: -8px;
									@include setwh(9px,13px);
								}
							}
						}
					}
					&__img {
						margin-top: 20px;
					}
				}
			}
			.titleCommon {
				margin-bottom: 32px;
				@include max-screen(767px) {
					margin-bottom: 13px;
				}
			}
		}
	}
	.link-pink {
		margin: 38px 0 69px;
		@include max-screen(767px) {
			margin: 17px 0 25px;
		}
		a {
			background-color: $color03;
			color: $white;
			display: block;
			font-weight: bold;
			margin: 0 auto;
			max-width: 487px;
			padding: 7px 0;
			@include setftRe(14,0.08em,21px);
		}
	}
	.content-block {
		margin-top: 25px;
		@include max-screen(767px) {
			margin-top: 15px;
		}
		.item {
			.textT, p {
				@include setftRe(14,0.08em,25px);
				@include max-screen(767px) {
					@include setftRe(12,0.08em,21px);
				}
			}
			.textT {
				color: $color03;
				font-weight: bold;
			}
			p {
				margin-bottom: 25px;
				@include max-screen(767px) {
					margin-bottom: 21px;
				}
			}
		}
	}
	.related-block {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 40px;
		}
	}
	.underline {
		color: $color03;
	    // background-size: 1px 1em;
	    // box-shadow:
	    //     inset 0 -0.1em white,
	    //     inset 0 -0.2em $color03;
	    // display: inline !important;
	    text-decoration: underline;
	}
}