@charset 'UTF-8';

.company-information {
	.main-img {
	    background-image: url(../img/company-information/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/company-information/mainimg_sp.jpg);
	    }
	}
	.main-content {
		padding: 58px 0 120px;
		@include max-screen(767px) {
			padding: 27px 0 100px;
		}
	}
	.message {
		.textTop {
			padding: 23px 0 73px;
			text-align: justify;
			@include max-screen(767px) {
				padding: 15px 0 36px;
			}
			p {
				margin-bottom: 25px;
				@include setftRe(14,0.08em,25px);
				@include max-screen(767px) {
					margin-bottom: 21px;
					@include setftRe(12,0.08em,21px);
				}
			}
		}
	}
	.company {
		.comList {
			font-size: 0;
			margin: 40px 0 95px;
			@include max-screen(767px) {
				margin: 17px 0 40px;
			}
			&__item {
				display: inline-block;
				margin-right: percentage(30/1130);
				width: percentage(550/1130);
				vertical-align: top;
				&:nth-child(2n) {
					margin-right: 0;
				}
				@include max-screen(767px) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 30px;
				}
				table {
					margin-top: 7px;
					@include max-screen(767px) {
						margin-top: 12px;
					}
					tr {
						border-bottom: 1px solid $color16;
					}
					th,td {
						padding: 10px 0;
						text-align: justify;
						@include setftRe(14,0.08em,25px);
						@include max-screen(767px) {
							display: block;
							padding: 0;
							width: 100%;
						}
					}
					th {
						width: 135px;
						@include max-screen(767px) {
							padding-top: 7px;
							width: 100%;
						}
					}
					td {
						@include max-screen(767px) {
							padding-bottom: 5px;
							@include setftRe(12,0.08em,21px);
						}
					}
				}
			}
		}
		.titleBground {
			@include max-screen(767px) {
				text-align: justify;
				@include setftRe(14,0.08em,25px);
			}
		}
	}
	.office {
		border-bottom: 1px solid $color16;
		margin-bottom: 85px;
		padding-bottom: 25px;
		text-align: justify;
		@include max-screen(767px) {
			margin-bottom: 50px;
			padding-bottom: 15px;
		}
		.titleCommon {
			margin-bottom: 30px;
			@include max-screen(767px) {
				margin-bottom: 19px;
			}
		}
		.titleOffice {
			background-color: $color01;
			color: $white;
			font-weight: bold;
			margin-bottom: 21px;
			padding: 7px 15px;
			@include setftRe(16,0.08em,28px);
			@include max-screen(767px) {
				margin-bottom: 11px;
				padding: 2.5px 13px;
			}
		}
		&__main {
			margin-bottom: 42px;
			@include max-screen(767px) {
				margin-bottom: 20px;
			}
		}
		&__left, &__right {
			float: left;
			@include max-screen(767px) {
				float: none;
			}
		}
		&__left {
			padding-right: 30px;
			padding-top: 4px;
			width: percentage(450/1130);
			@include max-screen(767px) {
				padding-right: 0;
				width: 100%;
			}
			.mapL {
				margin-top: 25px;
				a {
					display: inline-block;
					margin-top: 5px;
					position: relative;
					padding-left: 15px;
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px) {
						margin-top: 13px;
					}
					&::after {
						background: url(../img/company-information/ico_map.svg) no-repeat;
						content: '';
						left: 2px;
						position: absolute;
						top: 5px;
						@include setwh(9px,13px);
					}
					&::before {
						background-color: $black;
						content: '';
						left: 0;
						position: absolute;
						bottom: 6px;
						@include setwh(100%,1px);
					}
				}
			}
		}
		&__right {
			width: percentage(680/1130);
			@include max-screen(767px) {
				margin-top: 23px;
				width: 100%;
			}
			table {
				th {
					width: 160px;
					@include max-screen(767px) {
						width: 100%;
					}
				}
				th,td {
					padding-bottom: 19px;
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px) {
						padding-bottom: 0;
						display: block;
					}
				}
				td {
					a {
						text-decoration: underline;
						@include transition_c(all .5s);
						&:hover {
							text-decoration: none;
						}
					}
					@include max-screen(767px) {
						padding-bottom: 6px;
						@include setftRe(12,0.08em,21px);
					}
				}
			}
		}
	}
	.conberd {
		&__main {
			text-align: justify;
			padding-bottom: 90px;
			@include max-screen(767px) {
				padding-bottom: 40px;
			}
			table {
				tr {
					border-bottom: 1px solid $color16;
				}
				th,td {
					padding: 9px 0;
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px) {
						display: block;
						padding: 0;
						line-height: 20px;
					}
				}
				th {
					text-align: justify;
					width: 160px;
					@include max-screen(767px) {
						width: 100%;
						padding-top: 15px;
					}
				}
				td {
					@include max-screen(767px) {
						padding-bottom: 7px;
						@include setftRe(12,0.08em,16px);
					}
				}
			}
		}
	}
	.contact {
		text-align: justify;
		.contactP {
			font-weight: bold;
			margin: 19px 0 7px;
			@include setftRe(16,0.08em,32px);
			@include max-screen(767px) {
				margin: 22px 0 13px;
				@include setftRe(14,0.08em,24px);
			}
		}
		&__tel {
			background: url(../img/company-information/ico_tel.svg) left center no-repeat;
			background-size: 21px 17px;
			padding-left: 25px;
			a {
				color: $color03;
				font-weight: bold;
				text-decoration: underline;
				@include setftRe(24,0.08em,25px);
			}
		}
		&__text {
			margin-top: 45px;
			p {
				@include setftRe(14,0.08em,25px);
			}
		}
	}
	.related-block {
		padding-bottom: 150px;
		@include max-screen(767px) {
			padding-bottom: 37px;
		}
	}
}















