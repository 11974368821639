@charset 'UTF-8';

.sakura-seminar {
	.main-img {
	    background-image: url(../img/sakura-seminar/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/sakura-seminar/mainimgsp.jpg);
	    }
	}
	&__wrap {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 38px;
		}
	}
	.main-content {
		padding: 52px 0 170px;
		@include max-screen(767px) {
			padding: 23px 0 95px;
		}
	}
	&__text {
		text-align: justify;
		p {
			@include setftRe(14,0.08em,25px);
			@include max-screen(767px) {
				@include setftRe(12,0.08em,23px);
			}
		}
	}
	&__colorCat {
		background-color: $color19;
		margin: 40px 0 30px;
		padding: 25px 15px 20px;
		@include max-screen(767px) {
			margin: 20px 0 15px;
			padding: 20px 11.5px 28px;
		}
		.title-colorCat {
			font-weight: bold;
			@include setftRe(18,0.08em,25px);
			@include max-screen(767px) {
				@include setftRe(14,0.08em,23px);
			}
		}
		ul {
			font-size: 0;
			max-width: 974px;
			margin: 24px auto 0;
			@include max-screen(767px) {
				margin: 12px auto 0;
			}
			li {
				display: inline-block;
				margin-bottom: 15px;
				padding:  0 7px;
				vertical-align: top;
				width: 25%;
				@include max-screen(767px) {
					margin-bottom: 9px;
					padding:  0 3.5px;
					width: 50%;
				}
			}
			.listItem {
				color: $white;
				font-weight: bold;
				padding: 7px 5px;
				@include setftRe(14,0.08em,21px);
				@include max-screen(767px) {
					@include setftRe(11,0.08em,11px);
				}
				&--pink {
					background-color: $color23;
				}
				&--green {
					background-color: $color24;
				}
				&--blue {
					background-color: $color25;
				}
				&--yellow {
					background-color: $color26;
				}
			}
		}
	}
}