@charset 'UTF-8';

.economic-indicator {
	.main-img {
	    background-image: url(../img/economic-indicator/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/economic-indicator/mainimg_sp.jpg);
	    }
	}
	&__text {
		font-weight: bold;
		text-align: justify;
		@include setftRe(16,0.08em,28px);
		@include max-screen(767px) {
			@include setftRe(14,0.08em,25px);
		}
	}
	&__wrap {
		margin-bottom: 92px;
		height: 1200px;
		@include max-screen(767px) {
			margin-bottom: 100px;
			padding: 0 23px;
		}
	}
	.main-content {
		padding: 65px 0 145px;
		@include max-screen(767px) {
			padding: 28px 0 70px;
		}
	}
	.related-block {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 35px;
		}
	}
}