@charset 'UTF-8';

.cfd-lesson {
	.main-img {
	    background-image: url(../img/services/cfd-lesson/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/services/cfd-lesson/mainimg_sp.jpg);
	    }
	}

	.titleCommon {
		span {
			padding-left: 24px;
		}
	}

	.main-content {
		margin: 99px 0 110px;
		@include max-screen(767px) {
			margin: 0 0 65px;
		}
	}
	.lesson {
		font-weight: 500;
		@include setftRe(17,0.18em,32px);
		@include max-screen(767px) {
			@include setftRe(12,0.18em,12px);
		}
	}
	.cfd-lesson-title {
		font-weight: bold;
		margin: 22px 0;
		@include setftRe(28,0.08em,49px);
		@include max-screen(767px) {
			margin: 22px 0 15px;
			@include setftRe(20,0.08em,30px);
		}
	}
	.sub-title {
		margin-bottom: 16px;
		@include max-screen(767px) {
			margin-bottom: 12px;
			padding: 6px 12px 4px;
			@include setftRe(14,0.08em,25px);
		}
	}
	.anchor-list {
		margin-bottom: 82px;
		@include max-screen(767px) {
			margin-bottom: 34px;
		}
		li {
			float: left;
			width: percentage(178/1130);
			margin-left: percentage(12.4/1130);
			@include max-screen(1024px) {
				display: table;
			}
			@include max-screen(767px) {
				margin: 0;
				width: 50%;

			}
			&:first-child {
				margin-left: 0;
			}

			&:nth-child(even) {
				a {
					@include max-screen(767px) {
						border-right: none;
						border-left: none;
					}
				}
			}
			&:nth-child(odd) {
				a {
					@include max-screen(767px) {
						border-left: 0;
					}
				}
			}
			&:nth-child(-n+2) {
				a {
					@include max-screen(767px) {
						border-top: 1px solid $color01;
					}
				}
			}
			a {
				background-position: 15px center;
				font-size: 12px;
				padding: 11px 0 16px;
				@include max-screen(1024px) {
					display: table-cell;
					vertical-align: middle;
					padding: 5px 25px;
				}
				@include max-screen(767px) {
					border-top: none;
					padding: 5px 0 2px 28px;
					text-align: left;
					@include setftRe(10,0.08em,21px);
				}
				span {
					display: block;
					@include setftRe(11,0.18em,15px);
					@include max-screen(767px) {
						line-height: 12px;
					}
				}
			}
		}
	}
	.anchor-block {
		text-align: justify;
		.after-table-note {
		    color: $color02;
		    text-align: left;
		    @include setftRe(10,0.08em,18px);
		}
		.blockItem {
			.list {
				padding-bottom: 60px;
				@include max-screen(767px) {
					padding-bottom: 30px;
				}
				&__text {
					margin-bottom: 20px;
					@include setftRe(14,0.08em,25px);
					&--nomar {
						margin-bottom: 0;
					}
					@include max-screen(767px) {
						@include setftRe(12,0.08em,21px);
					}

					.underline {
					    background-size: 1px 1em;
					    box-shadow:
					        inset 0 -0.1em white,
					        inset 0 -0.2em $color03;
					    display: inline;
					}
					a {
						color: $color03;
						display: inline-block;
						padding-bottom: 2px;
						&.arrow {
							margin-bottom: 7px;
							padding-left: 15px;
							position: relative;
							&::before {
								background: url(../img/services/cfd/ico_pink.svg) no-repeat;
								content: '';
								left: 2px;
								position: absolute;
								top: 0;
								@include setwh(9px,13px);
							}
						}
					}
				}
				&__img {
					padding-top: 18px;
					@include max-screen(767px) {
						padding-top: 3px;
					}
				}
			}
		}
		&__block01 {
			margin-bottom: 37px;
			@include max-screen(767px) {
				margin-bottom: 15px;
			}
		}
		&__block02 {
			margin-bottom: 45px;
			@include max-screen(767px) {
				margin-bottom: 30px;
			}
		}
		&__block03 {
			margin-bottom: 65px;
			@include max-screen(767px) {
				margin-bottom: 40px;
			}
			.after-table-note {
				@include max-screen(767px) {
					padding-top: 30px;
				}
			}
		}
		&__block04 {
			margin-bottom: 40px;
			@include max-screen(767px) {
				margin-bottom: 12px;
			}
		}
		&__block05 {
			margin-bottom: 70px;
			@include max-screen(767px) {
				margin-bottom: 35px;
			}
		}
		&__block06 {
			.after-table-note {
				margin-top: 15px;
			}
		}
	}
	.button-link {
		margin: 45px 0 50px -10px;
		text-align: justify;
		@include max-screen(767px) {
			margin: 23px 0 20px 0;
		}
		.btn {
			display: inline-block;
    		margin-right: 13px;
    		text-align: center;
    		@include max-screen(767px) {
    			display: block;
    			margin-right: 0;
    			margin-bottom: 10px;
    		}
			.btnBox {
			    padding: 16px 0 19px;
			    @include setftRe(16,0.08em,28px);
			}
		}
	}
	.textTop {
		margin-bottom: 25px;
		&--mar {
			margin-bottom: 0;
		}
		p {
			@include setftRe(14,0.08em,25px);
			@include max-screen(767px) {
				@include setftRe(12,0.08em,21px);
			}
		}
	}
	.related-block {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 35px;
		}
	}
}