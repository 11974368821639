@charset 'UTF-8';

.koza-apply {
	.main-img {
	    background-image: url('../img/koza_apply/mainimg.jpg');
	    @include max-screen(767px) {
	    	background-image: url('../img/koza_apply/mainimg_sp.jpg');
	    }
	}

	.main-content{
		margin: 48px 0 130px;
		text-align: left;
		@include max-screen(767px) {
			margin: 25px 0 48px;
		}
	}

	.koza-apply-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 41px;
		}
	}

	.first-text{
		@include setftRe(14,0.08em,25px);
		@include max-screen(767px){
			@include setftRe(12,0.08em,25px);
			margin: 25px auto 20px;
		}
	}

	.privacy-cover{
		margin-top: 35px;
		height: 250px;
		border: 1px solid $color01;
		padding: 15px;
		padding-left: 25px;
		overflow: hidden;
		@include max-screen(767px){
			margin-top: 30px;
			padding: 10px;
			padding-right: 5px;
		}
		.privacy-content{
			height: 220px;
			overflow-y: auto;
			@include max-screen(767px){
				padding-right: 5px;
			}
			.text{
				@include setftRe(14,0.08em,25px);
				@include max-screen(767px){
					@include setftRe(12,0.08em,21px);
				}
				span{
					color: $color03;
					display: block;
					font-weight: bold;
				}
				strong{
					@include setftRe(16,0.08em,28px);
					@include max-screen(767px){
						line-height: 23px;
					}
				}
			}
		}
	}

	.to-form-btn{
		width: 320px;
		margin: 47px auto 0;
		text-align: center;
		padding: 12px 0;
		@include setftRe(14,0.08em,25px);
		font-weight: bold;
		@include max-screen(767px){
			@include setftRe(14,0.08em,25px);
			width: 100%;
			margin-top: 15px;
		}
	}

	.step01{
		.table-form{
			.koza-apply-birth-day-year{
				width: 70px;
			}
			.koza-apply-birth-day-month,
			.koza-apply-birth-day-day{
				width: 40px;
			}
		}

		.koza-apply-investment-objective-list,
		.koza-apply-bank-list{
			li{
				@include max-screen(767px){
					display: block;
					padding-right: 0;
				}
			}
		}
	}
}