@charset "UTF-8";

.services {
	.main-img {
	    background-image: url(../img/services/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/services/mainimg_sp.jpg);
	    }
	}
	&__main {
		font-size: 0;
		padding: 75px 0 153px;
		@include max-screen(767px) {
			padding: 35px 0 12px;
		}
		.list {
			display: inline-block;
			margin-bottom: 55px;
			margin-right: percentage(26/1130);
			vertical-align: top;
			width: percentage(552/1130);
			@include max-screen(767px) {
				margin-right: 0;
				margin-bottom: 45px;
				width: 100%;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
			&__top {
				position: relative;
				.textPos {
					left: 50%;
					position: absolute;
					padding: 0 15px;
					top: 54%;
					transform: translate(-50%,-50%);
					width: 100%;
					em, span {
						display: block;
						font-weight: bold;
					}
					em {
						color: $white;
						@include setftRe(35,0.08em,40px);
						@include max-screen(767px) {
							@include setftRe(18,0.08em,21px);
						}
					}
					span {
						color: $color03;
						@include setftRe(15,0.13em,40px);
						@include max-screen(767px) {
							@include setftRe(8,0.13em,21px);
						}
					}
				}
			}
			&__text {
				margin-top: 18px;
				text-align: justify;
				@include max-screen(767px) {
					margin-top: 5px;
				}
				p {
					margin-bottom: 25px;
					@include setftRe(14,0.04em,25px);
					@include max-screen(767px) {
						margin-bottom: 0;
						@include setftRe(12,0.04em,21px);
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			&__link {
				margin-top: 25px;
				@include max-screen(767px) {
					margin-top: 15px;
				}
				.btnAdd-link {
					font-size: 12px;
					@include max-screen(767px) {
						line-height: 12px;
					}
				}
			}
		}
	}
	.related-block {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 40px;
		}
	}
}