@charset 'UTF-8';

.sakura-seminar-lecturer {
	.main-img {
	    background-image: url(../img/sakura-seminar-lecturer/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/sakura-seminar-lecturer/mainimgsp.jpg);
	    }
	}
	.titleCommon {
		span {
			@include max-screen(767px) {
				@include setftRe(8,0.08em,14px);
			}
		}
	}
	.main-content {
		padding: 57px 0 72px;
		@include max-screen(767px) {
			padding: 32px 0 40px;
		}
	}
	.list {
		font-size: 0;
		margin-top: 52px;
		text-align: justify;
		@include max-screen(767px) {
			margin-top: 20px;
			padding: 0 5px;
		}
		.listItem {
			display: inline-block;
			margin-right: percentage(30/1130);
			margin-bottom: 85px;
			vertical-align: top;
			width: percentage(550/1130);
			@include max-screen(767px) {
				margin-right: 0;
				margin-bottom: 33px;
				width: 100%;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
			&__wrap {
				padding-bottom: 32px;
				@include max-screen(767px) {
					padding-bottom: 11px;
				}
			}
			&__left {
				float: left;
				padding-top: 7px;
				width: 162px;
				@include max-screen(767px) {
					float: none;
					margin: 0 auto;
					padding-top: 0;
					width: 92px;
				}
			}
			&__right {
				padding-left: 184px;
				@include max-screen(767px) {
					padding-left: 0;
				}
			}
			.textTop {
				@include max-screen(767px) {
					margin-top: 10px;
				}
				span {
					vertical-align: middle;
					@include max-screen(767px) {
						display: block;
						text-align: center;
					}
					&.nameJ {
						font-weight: bold;
						padding-right: 10px;
						@include setftRe(20,0.08em,30px);
						@include max-screen(767px) {
							padding-right: 0;
							@include setftRe(16,0.08em,21px);
						}
					}
					&.nameE {
						color: $color02;
						font-weight: bold;
						@include setftRe(14,0.08em,30px);
						@include max-screen(767px) {
							@include setftRe(8,0.08em,21px);
						}
					}
				}
			}
			.textP {
				margin-top: 16px;
				@include max-screen(767px) {
					margin-top: 3px;
				}
				p {
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px) {
						@include setftRe(12,0.08em,21px);
					}
				}
			}
			.videoIn {
				margin-top: 20px;
				@include max-screen(767px) {
					margin-top: 15px;
					text-align: center;
				}
				iframe{
					max-width: 100%;
					@include max-screen(767px) {
						width: 100%;
						height: 175px;
					}
				}
			}
		}
	}
	.related-block {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 40px;
		}
	}
}