@charset 'UTF-8';

.smartcx {
	.main-img {
	    background-image: url(../img/services/smartcx/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/services/smartcx/mainimg_sp.jpg);
	    }
	}

	.main-content{
		margin: 100px 0 180px;
		@include max-screen(767px) {
			margin: 0 0 80px;
		}
	}

	.smartcx-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 41px;
		}
	}

	.anchor-list{
		li{
			float: left;
			width: 18.9%;
			margin-left: 1.375%;
			@include max-screen(767px) {
				margin: 0;
				width: 50%;

			}
			&:first-child{
				margin-left: 0;
			}

			&:nth-child(even){
				a{						
					@include max-screen(767px) {
						border-left: none;
					}
				}
			}
			&:nth-child(-n+2){
				a{						
					@include max-screen(767px) {
						border-top: 1px solid $color01;
					}
				}
			}
			a{
				font-size: 12px;
				padding: 9px 0 8px;
				@include max-screen(1129px) {
					background-position: 10px center;
				}
				@include max-screen(767px) {
					@include setftRe(10,0.08em,21px);
					text-align: left;
					padding-left: 28px;
					border-top: none;
				}
			}
		}
	}

	.sub-title{
		background-color: $color01;
		color: $white;
		@include setftRe(16, 0.08em, 25px);
		text-align: left;
		font-weight: bold;
		padding: 8px 17px 9px;
		@include max-screen(767px){
			@include setftRe(14,0.08em, 21px);
			padding: 5px 9px;
		}

		&--bg-pink{
			background-color: $color03;
		}

		&.mt50{
			margin-top: 50px;
		}
	}

	.anchor-block{
		margin-top: 85px;
		@include max-screen(767px) {
			margin-top: 25px;
		}
		.titleCommon{
			margin-bottom: 48px;
			@include max-screen(767px) {
				margin-bottom: 20px;
			}
			span{
				padding-left: 10px;
				@include max-screen(767px) {
					display: none;
				}
			}
		}
		.after-table-note{
			color: $color02;
			@include setftRe(10,0.08em,18px);
			text-align: left;
			margin-top: 30px;
			@include max-screen(767px) {
				margin-top: 10px;
			}
		}

		.table{
			&:nth-of-type(even){
				margin-top: 50px;
				@include max-screen(767px) {
					margin-top: 25px;
				}
			}

			tr{
				@include max-screen(767px) {
					font-size: 0;
				}
			}

			td,
			th{
				vertical-align: middle;
			}

			th{
				@include max-screen(767px) {
					display: none;
				}
			}
			td{
				@include max-screen(767px) {
					display: block;
				}
			}
			.table-heading,
			td{
				p{
					@include max-screen(767px) {
						position: relative;
						display: inline-block;
						letter-spacing: 0;
						padding-left: 15px;
						@include calc-css(width, '100% - 130px');
						vertical-align: middle;
					}
					&:before{
						@include max-screen(767px) {
							position: absolute;
							left: -130px;
							top: 0;
							width: 130px;
							height: 100%;
							content: "";
							background-color: rgba($color03, 0.2);
						}
					}
				}
				&:before{
					@include max-screen(767px) {
						content: attr(data-heading);
						font-weight: bold;
						width: 130px;
						display: inline-block;
						@include setftRe(12, 0em, 18px);
						padding: 0 8px;
						vertical-align: middle;
						white-space: pre;
					}
				}

				&.sub-title{
					@include max-screen(767px) {
						padding-left: 9px;
					}
					&:before{
						@include max-screen(767px) {
							display: none;
						}
					}
				}
			}
		}

		.content-block{
			text-align: left;
			.text{
				@include setftRe(14,0.08em,25px);
				@include max-screen(767px) {
					@include setftRe(12,0.08em,21px);
				}

				span{
					display: block;
					color: $color03;
					font-weight: bold;
				}

			}
		}

		.table{
			width: 1130px;
			@include max-screen(767px) {
				width: 100%;
			}
		}

		&__block01{
			.content-block{
				.sub-block02{
					margin-top: 35px;
					@include max-screen(767px) {
						margin-top: 15px;
					}

					.after-table-note{
						margin-top: 10px;
						@include max-screen(767px) {
							margin-top: 5px;
						}
					}
				}

				.sub-block03{
					margin-top: 50px;
					@include max-screen(767px) {
						margin-top: 25px;
					}
					.sub-title{
						margin-bottom: 10px;
					}

					.after-table-note{
						margin-top: 10px;
						@include max-screen(767px) {
							margin-top: 5px;
						}
					}
				}

				.sub-block04{
					margin-top: 50px;
					@include max-screen(767px) {
						margin-top: 25px;
					}
					.after-table-note{
						&.mt25{
							margin-top: 25px;
							@include max-screen(767px) {
								margin-top: 10px;
							}
						}
					}
				}

				.sub-block05{
					margin-top: 70px;
					@include max-screen(767px) {
						margin-top: 35px;
					}
					.sub-title{
						margin-bottom: 10px;
					}
					.after-sub-title{
						margin-bottom: 20px;
					}

					.after-table-note{
						&.mt20{
							margin-top: 20px;
						}
					}
				}

				.smartcx-img01{
					margin-top: 40px;
					@include max-screen(767px){
						width: 100%;
						margin-top: 20px;
					}
				}

				.smartcx-img02{
					margin-top: 10px;
					@include max-screen(767px){
						width: 100%;
						margin-top: 5px;
					}
				}

				.large-text{
					@include setftRe(28,0.06em,49px);
					font-weight: bold;
					@include max-screen(767px){
						@include setftRe(18,0.06em,32px);
					}
					span{
						color: $color03;
					}
				}

				.table-type02{
					.sub-title{
						@include setftRe(14,0.08em,21px);
						@include max-screen(767px){
							@include setftRe(11,0.06em, 17px);
							padding: 5px;
						}
					}
					tr{
						&:first-child{
							td{
								border-top: none;
							}
						}
					}
					td{
						vertical-align: middle;
						border: 1px solid $color08;
						@include max-screen(767px){
							display: block;
							width: 100%;
							border-top: none;
						}
						&.title{
							width: 245px;
							@include setftRe(18,0.06em,32px);
							font-weight: bold;
							text-align: center;
							padding-top: 1px;
							@include max-screen(767px){
								width: 100%;
								@include setftRe(14,0.06em,25px);
							}
							span{
								vertical-align: middle;
								padding-bottom: 3px;
								display: inline-block;
								margin-right: 3px;
								color: $color03;
								@include setftRe(28,0.06em,49px);
								@include max-screen(767px){
									@include setftRe(20,0.06em,35px);
								}
							}
						}
						&.text{
							padding: 20px;
							@include setftRe(14,0.08em,21px);
							@include max-screen(767px){
								@include setftRe(12,0.08em,21px);
								padding: 5px 15px;
							}
						}
					}
				}

				.table{
					th{
						text-align: center;
					}
					td{
						padding-left: 10px;
						@include max-screen(767px){
							padding-left: 0;
						}
						&.sub-title{
							@include max-screen(767px) {
								padding-left: 9px;
							}
							&:before{
								@include max-screen(767px) {
									display: none;
								}
							}
						}
					}
				}
				.table01{
					margin-top: 25px;
					th,
					td{
						@include calc-css(width, '100% / 9');
						@include max-screen(767px) {
							width: 100%;
						}
					}
				}
				.table02{
					margin-top: 50px;
					@include max-screen(767px) {
						margin-top: 25px;
					}
					th,
					td{
						@include calc-css(width, '100% / 7');
						@include max-screen(767px) {
							width: 100%;
						}
					}
				}
				.table03{
					margin-top: 50px;
					@include max-screen(767px) {
						margin-top: 25px;
					}
					th,
					td{
						@include calc-css(width, '100% / 5');
						@include max-screen(767px) {
							width: 100%;
						}
					}
					td{
						padding-left: 0;
						text-align: center;
						@include max-screen(767px){
							text-align: left;
						}
					}
				}
				.table04{
					margin-top: 75px;
					@include max-screen(767px) {
						margin-top: 40px;
					}
					th,
					td{
						@include calc-css(width, '100% / 3');
						@include max-screen(767px) {
							width: 100%;
						}
					}
					td{
						padding-left: 0;
						text-align: center;
						@include max-screen(767px){
							text-align: left;
						}
					}
				}
			}
		}

		&__block02{
			margin-top: 140px;
			@include max-screen(767px) {
				margin-top: 50px;
			}
			.first-sub{
				margin-bottom: 50px;
				@include max-screen(767px) {
					margin-bottom: 25px;
				}
			}
			.table{
				th,
				td{
					@include calc-css(width, '100% / 5');
					@include max-screen(767px) {
						width: 100%;
					}
				}
			}
			.table06{
				margin-top: 45px;
				@include max-screen(767px) {
					margin-top: 20px;
				}
			}
		}

		&__block03{
			.table{
				th,
				td{
					@include calc-css(width, '100% / 4');
					@include max-screen(767px) {
						width: 100%;
					}
				}
			}
			.table08{
				margin-top: 75px;
				@include max-screen(767px) {
					margin-top: 40px;
				}
			}
		}
		&__block04{
			.table09{
				th,
				td{
					@include calc-css(width, '100% / 4');
					@include max-screen(767px) {
						width: 100%;
					}
				}
			}

			.table10{
				margin-top: 68px;
				@include max-screen(767px) {
					margin-top: 35px;
				}
				th,
				td{
					@include calc-css(width, '100% / 8');
					@include max-screen(767px) {
						width: 100%;
					}
				}
			}
		}
		&__block01,
		&__block05{
			.titleCommon{
				margin-bottom: 30px;
				@include max-screen(767px) {
					margin-bottom: 15px;
				}
			}
		}
	}
}