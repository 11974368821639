@charset 'UTF-8';

/*-----------------------------------------------------------
Bread
------------------------------------------------------------*/
.breadcrumb {
	background-color: $color19;
	padding: 20.5px 22px;
	text-align: justify;
	@include max-screen(767px) {
		padding: 6px 15px;
	}
	.breadcrumb-list {
		li, a {
			color: $color12;
		}
		li {
			display: inline-block;
			font-size: 14px;
			letter-spacing: 0.08em;
			padding-right: 40px;
			position: relative;
			vertical-align: top;
			@include max-screen(767px) {
				padding-right: 30px;
				@include setftRe(10,0.08em,20px);
			}
			&::after {
				background: url(../img/common/icon/ico_breadcrumb.svg) no-repeat;
				content: '';
				right: 10px;
				position: absolute;
				top: 2px;
				@include setwh(10px,10px)
				@include max-screen(767px) {
					background-size: 100% auto;
					right: 7px;
					top: 5px;
					@include setwh(7px,10px)
				}
			}
			&:last-child {
				&::after {
					background: none;
				}
			}
		}
	}
}
