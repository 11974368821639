@charset 'UTF-8';

.top {
	&__blockImg {
		&__left, &__right {
			float: left;
			width: 50%;
			@include max-screen(767px) {
				float: none;
				width: 100%;
			}
		}
		&__left {
			background: url(../img/top/pic_blockImg01.jpg) center center no-repeat;
			background-size: cover;
			min-height: 496px;
			position: relative;
			@include max-screen(767px) {
				background: none;
				font-size: 0;
				min-height: auto;
			}
			img {
				width: 100%;
			}
		}
		&__right {
			font-size: 0;
			li {
				display: inline-block;
				min-height: 248px;
				width: 50%;
				vertical-align: top;
				position: relative;
				@include max-screen(767px) {
					min-height: 120px;
				}
				&:nth-child(1) {
					background: url(../img/top/pic_blockImg02.jpg) center center no-repeat;
					background-size: cover;
				}
				&:nth-child(2) {
					background: url(../img/top/pic_blockImg03.png) center center no-repeat;
					background-size: cover;
				}
				&:nth-child(3) {
					background: url(../img/top/pic_blockImg04.jpg) center center no-repeat;
					background-size: cover;
				}
				&:nth-child(4) {
					background: url(../img/top/pic_blockImg05.jpg) center center no-repeat;
					background-size: cover;
				}
				.banerTop {
					left: 0;
					top: 0;
					position: absolute;
					@include setwh(100%,100%);
					&:hover {
						opacity: 1;
					}
					.btnMore{
						display: block;
					}
				}
				.textImg {
					left: 0;
					top: 50%;
					transform: translate(0,-50%);
					width: 100%;
					position: absolute;
					p {
						color: $white;
						font-weight: bold;
						margin-bottom: 25px;
						@include setftRe(22,0.08em,32px);
						@include max-screen(767px) {
							margin-bottom: 10px;
							@include setftRe(11,0.08em,16px);
						}
					}
				}
			}
		}
	}
	&__blockItem {
		font-size: 0;
		margin: 53px -13px 65px;
		@include max-screen(767px) {
			margin: 25px 0 0;
		}
		&__ul {
			display: inline-block;
			vertical-align: top;
			padding: 0 13px;
			width: 50%;
			@include max-screen(767px) {
				padding: 0;
				width: 100%;
			}
		}
		&__ul {
			@include max-screen(767px) {
				margin-bottom: 40px;
			}
			&.topBlog {
				.list {
					&__item {
						padding: 27px 0;
						@include max-screen(767px) {
							padding: 15px 0;
						}
						&__text {
							span {
								@include setftRe(14,0.08em,21px);
							}
						}
					}
				}
			}
			&.topNews {
				.list {
					&__item {
						padding: 25px 0;
						@include max-screen(767px) {
							padding: 15px 0;
						}
					}
				}
			}
		}
		.btnAdd-link {
			margin-top: 30px;
			@include max-screen(767px) {
				margin-top: 20px;
			}
		}
	}
	&__video {
		border-top: 2px solid $color16;
		padding: 65px 0 87px;
		@include max-screen(767px) {
			border: none;
			padding: 0;
		}
		&__main {
			font-size: 0;
			margin: 0 -13px;
			@include max-screen(767px) {
				margin: 12px 0 0;
			}
			.videoList {
				display: inline-block;
				vertical-align: top;
				padding: 0 13px;
				width: 50%;
				@include max-screen(767px) {
					margin-bottom: 25px;
					padding: 0;
					width: 100%;
				}
				&__main {
					margin-top: 20px;
					@include max-screen(767px) {
						margin-top: 13px;
					}
					iframe{
						width: 100%;
						@include max-screen(767px) {
							height: 165px;
						}

					}
				}
			}
		}
	}
	&__chart {
		@include max-screen(767px) {
			margin-top: 30px;
		}
		&__main {
			font-size: 0;
			margin: 40px -15px 0;
			@include max-screen(767px) {
				margin: 20px 0 0;
			}
		}
		.chartList {
			display: inline-block;
			margin-bottom: 28px;
			padding: 0 15px;
			vertical-align: top;
			width: 50%;
			@include max-screen(767px) {
				padding: 0;
				width: 100%;
			}
			&__wrap {
				background-color: $color20;
				padding: 12px 18px 18px;
				@include max-screen(767px) {
					padding: 10px 0 0;
				}
				&__inner {
					background-color: $white;
					overflow: hidden;
					padding: 18px 20px 5px;
					@include max-screen(1180px) {
						padding: 10px 10px 0;
					}
					@include max-screen(767px) {
						padding: 6px 0 0;
					}
					*{
						@include max-screen(767px) {
							max-width: 100%;
						}
					}
				}
			}
			&__title {
				font-weight: bold;
				text-align: justify;
				padding: 0 0 10px 5px;
				@include setftRe(16,0.08em,28px);
				@include max-screen(767px) {
					padding: 0 0 5px 15px;
					@include setftRe(14,0.08em,21px);
				}
				em {
					color: $color03;
					font-style: normal;
				}
			}
		}
	}
	&__trading {
		margin-top: 55px;
		@include max-screen(767px) {
			margin-top: 0;
		}
		&__main {
			font-size: 0;
			margin: 33px -13px 75px;
			@include max-screen(767px) {
				margin: 9px 0;
			}
		}
		.tradingList {
			display: inline-block;
			vertical-align: top;
			padding: 0 13px;
			width: 50%;
			@include max-screen(767px) {
				margin-bottom: 35px;
				padding: 0;
				width: 100%;
			}
			&--bottom {
				@include max-screen(767px) {
					margin-bottom: 20px;
				}
			}
			&:first-child {
				.tradingList__main {
					@include max-screen(767px) {
						background-color: transparent;
					}
				}
			}
			&__main {
				background-color: $color19;
				padding: 21px 34px 33px;
				@include max-screen(767px) {
					padding: 15px;
				}
				h3, .textTrading {
					text-align: justify;
					@include max-screen(767px) {
						text-align: center;
					}
				}
				h3 {
					font-weight: bold;
					@include setftRe(16,0.08em,28px);
				}
				.textTrading {
					color: $color02;
					margin-top: 5px;
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px) {
						@include setftRe(12,0.08em,21px);
					}
				}
				select, option {
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px) {
						@include setftRe(13,0.08em,23px);
					}
				}
				.btnTrading {
					background-color: $color03;
					border: none;
					border-radius: 0;
					color: $white;
					cursor: pointer;
					font-weight: bold;
					margin-top: 20px;
					padding: 7px;
					width: 100%;
					@include setftRe(14,0.08em,21px);
					&:hover {
						opacity: 0.7;
					}
				}
				input[type="button"] {
				    border-radius: 0;
				}
			}
		}
		.selectBg {
			margin-top: 22px;
			text-align: left;
			&--bottom {
				margin-top: 15px;
			}
		}
		.get-commodity-table {
			margin-top: 30px;
			@include max-screen(767px) {
				margin: 23px 0 10px;
			}
			tr {
				th {
					font-weight: bold;
					padding-bottom: 7px;
					text-align: justify;
					@include setftRe(14,0.08em,25px);
				}
				td {
					border: 2px solid $color01;
					font-weight: bold;
					@include setftRe(12,0.08em,29px);
					background-color: $white;
				}
			}
			.get-commodity-table-left {
				background-color: $color10;
				color: $white;
				width: 120px;
				@include max-screen(767px) {
					width: 90px
				}
			}
			.get-commodity-table-right {
				padding: 0 20px;
				text-align: left;
				@include max-screen(767px) {
					padding: 0 12px;
				}
			}
		}
	}
	&__wrap {
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 40px;
		}
	}

	.sakura-dialog{
		z-index: 99999 !important;
		.ui-dialog-titlebar{
			text-align: left;
			padding: 15px 15px;
			font-size: 18px;
			line-height: 1;
		}
		.ui-dialog-titlebar-close{
			width: 30px;
			height: 30px;
			right: 15px;
			margin-top: -15px;
			background-color: transparent;
			border: none;
		}
		.ui-dialog-buttonpane{
			background: #606060;
			padding: 20px 15px;
		}
		.ui-dialog-content{
			padding: 20px 20px;
			overflow: hidden;
			background-color: $black;
		}
		.okClass{
			background: #428bca;
			border-color: #428bca;
			color: $white;
			font-weight: bold;
			&:hover{	
				@include min-screen(768px) {
					opacity: 0.5;
				}
			}
			&:focus{
				border-color: #428bca;
				outline: none;
			}
		}
		iframe{
			width: 100%;
			height: 100%;
			border: none;
		}
	}
}












