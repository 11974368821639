@charset 'UTF-8';

.policy {
	.main-img {
	    background-image: url('../img/policy/mainimg.jpg');
	    @include max-screen(767px) {
	    	background-image: url('../img/policy/mainimg_sp.jpg');
	    }
	}

	.main-content{
		margin: 100px 0 115px;
		text-align: left;
		@include max-screen(767px) {
			margin: 30px 0 90px;
		}

		.titleCommon{
			@include max-screen(767px){
				padding-bottom: 6px;
			}
		}
	}

	.policy-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 41px;
		}
	}
	.text{
		margin-top: 30px;
		@include setftRe(14,0.08em,25px);
		@include max-screen(767px){
			margin-top: 20px;
			@include setftRe(12,0.08em,21px);
		}
		span{
			color: $color03;
			display: block;
			font-weight: bold;
		}
		strong{
			@include setftRe(16,0.08em,28px);
			@include max-screen(767px){
				line-height: 23px;
			}
		}

		&.mt10{
			margin-top: 10px;
			@include max-screen(767px){
				margin-top: 5px;
			}
		}
		&.mt20{
			margin-top: 20px;
			@include max-screen(767px){
				margin-top: 10px;
			}
		}
		&.mt80{
			margin-top: 80px;
			@include max-screen(767px){
				margin-top: 40px;
			}
		}
		&.smaller-sp{
			strong{
				@include max-screen(767px){
					@include setftRe(14,0.08em,28px);
				}
			}
		}
	}

	.block{
		&.block02{
			margin-top: 110px;
			@include max-screen(767px){
				margin-top: 55px;
			}
		}
	}
}