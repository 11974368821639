@charset 'UTF-8';

.search-top {

	.main-content{
		margin: 70px 0 165px;
		text-align: left;
		@include max-screen(767px) {
			margin: 30px 0 70px;
		}
	}

	.search-top-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 41px;
		}
	}

	.result-list{
		.result-item{
			margin-top: 40px;
			&:first-child{
				margin-top: 0;
			}
			p{
				line-height: 21px;
				letter-spacing: 0.08em;
			}
			.page-name{
				font-size: 16px;
				font-weight: bold;
				&:before{
					content: "\25B6";
					display: inline-block;
					vertical-align: middle;
				}
			}
			.page-desc{
				font-size: 14px;
				margin: 3px 0 5px;
				span{
					color: $color03;
				}
			}
			.page-link{
				font-size: 14px;
				color: $color03;
				text-decoration: underline;				
			}
		}
	}
	.wp-pagenavi {
		margin-top: 50px;
		@include max-screen(767px) {
			margin: 30px 0 113px;
		}
		.pages{
			display: none;
		}
		.previouspostslink, .nextpostslink {
			border: 2px solid $black;
			display: inline-block;
			position: relative;
			@include setwh(30px,40px);
			&::before {
				border-top: 4.75px solid transparent;
				border-bottom: 4.75px solid transparent;
				content: '';
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
			}
		}
		.previouspostslink {
			margin-right: 25px;
			@include max-screen(767px) {
				margin-right: 25px;
			}
			&::before {
				border-right: 6.5px solid $black;
			}
		}
		.nextpostslink {
			margin-left: 25px;
			@include max-screen(767px) {
				margin-left: 25px;
			}
			&::before {
				border-left: 6.5px solid $black;
			}
		}
		a, span {
			color: $black;
			font-weight: bold;
			padding: 0 8px;
			vertical-align: middle;
			@include font-sizeRe(14);
			@include max-screen(767px) {
				padding: 0 7px;
				@include font-sizeRe(12);
			}
		}
	}
}