@charset 'UTF-8';

.siryo {
	.main-img {
		background-image: url('../img/siryo/mainimg.jpg');
		@include max-screen(767px) {
			background-image: url('../img/siryo/mainimg_sp.jpg');
		}
	}

	.siryo-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 41px;
		}

		.titleCommon{
			margin-bottom: 30px;
			@include max-screen(767px){
				padding-bottom: 6px;
				margin-bottom: 25px;
			}
		}
	}

	.main-content{
		margin: 70px 0 150px;
		text-align: left;
		@include max-screen(767px) {
			margin: 25px 0 48px;
		}
	}

	.first-text{
		text-align: left;
		@include setftRe(14,0.08em,25px);
		@include max-screen(767px){
			@include setftRe(12,0.08em,21px);
			margin: 25px 0 50px;
		}

		strong{
			@include setftRe(14,0.08em,25px);
			color: $color03;
		}
	}

	.step01{
		.table-form{
			.doc-request-birth-day-year{
				width: 70px;
			}
			.doc-request-birth-day-month,
			.doc-request-birth-day-day{
				width: 40px;
			}
			.doc-request-trigger-list,
			.doc-request-investment-experience-list{
				li{
					margin-right: 20px;
					@include max-screen(767px){
						margin-right: 0;
						display: block;
					}
				}
			}
			.form-input-list{
				li{
					@include max-screen(767px){
						@include setftRe(12,0.08em,25px);
					}
				}
			}
			.doc-request-type-list{
				li{
					@include max-screen(767px){
						padding-right: 0;
					}
				}
			}
			.form-textarea{
				@include calc-css(width, '100% - 25px');
				min-height: 66px;
				@include max-screen(767px){
					width: 100%;
				}
			}
		}
	}
}