// Author: Kyohei Maeda



/* argument */
$black: #000;
$white: #FFF;
$color01: #333;
$color02: #989898;
$color03: #D43D91;
$color04: #FF0000;
$color05: #1C253F;
$color06: #A93179;
$color07: #04040C;
$color08: #E5E5E5;
$color09: #4B4B4B;
$color10: #666;
$color11: #212121;
$color12: #808080;
$color13: #4D4D4D;
$color14: #1C253F;
$color15: #B3B3B3;
$color16: #CBCBCB;
$color17: #16162D;
$color18: #C3AA6E;
$color19: #F1F1F1;
$color20: #F6F6F6;
$color21: #BAA449;
$color22: #917E2C;
$color23: #EB4984;
$color24: #5CB85C;
$color25: #428BCA;
$color26: #FFAA00;
$color27: #F2F2F2;
$color28: #F9E8F3;




//aspect-ratio fixed box
//==================================================
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before{
		display: block;
		content: " ";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

//font-size with rem
//==================================================
@mixin font-size($size: 14) {
	font-size: $size + px;
	font-size: ($size / 10)  + rem;
}

@mixin my_menu_effect($items, $delay, $direct) {
	@each $item in $items {
		&:nth-child(#{$item}) {
			@include transition_c(#{$delay}ms, 'delay');
		}

		@if ($direct == "open") {
			$delay: $delay + 50;
		} @else {
			$delay: $delay - 50;
		}
	}
}

$browser_prefix: ("-webkit-", "-moz-", "-ms-", "-o-", "");

@mixin transition_c ($content, $opt: "") {
	@each $prefix in $browser_prefix {
		@if ($opt == "") {
			#{$prefix}transition: $content;
		} @else {
			#{$prefix}transition-#{$opt}: $content;
		}
	}
}

@mixin transform_c ($content) {
	@each $prefix in $browser_prefix {
		#{$prefix}transform: $content;
	}
}

@mixin calc-css ($prefix, $content) {
    #{$prefix}: -webkit-calc(#{$content});
    #{$prefix}: -moz-calc(#{$content});
    #{$prefix}: calc(#{$content});
}

@mixin border ($content, $side: "") {
	@if ($side == "") {
		border: #{$content};
	} @else {
		border-#{$side}: #{$content};
	}
}

@mixin border-radius ($radius, $side: "") {
	@if ($side == "" ) {
		-webkit-border-radius: $radius;
		-moz-border-radius: $radius;
		border-radius: $radius;
	} @else {
		-webkit-border-#{$side}-radius: $radius;
		-moz-border-#{$side}-radius: $radius;
		border-#{$side}-radius: $radius;
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

//==================================================
// Black and White Opacity Functions
// Call | .css { background: black(0.5); }
//==================================================
@function black($opacity) {
	@return rgba(black, $opacity)
}
@function white($opacity) {
	@return rgba(white, $opacity)
}

//==================================================
// Centering
// Call | .css { @include centerer; }
//==================================================

@mixin centerer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// If you want to be able to center in only one direction...

@mixin centerer($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if ($vertical) {
		top: 50%;
		transform: translate(0, -50%);
	}
}

//==================================================
// Triangle helper mixin
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color 
// @param {Length} $size [1em] - Triangle size
// Call | .css::before { @include triangle(bottom, #000, 5px); }
//==================================================

@mixin triangle($direction, $color: currentcolor, $size: 1em, $distance: 5px) {
	@if not index(top right bottom left, $direction) {
		@error "Direction must be either `top`, `right`, `bottom` or `left`.";
	}

	width: 0;
	height: 0;
	content: '';
	z-index: 2;
	margin: $distance;
	border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

	$perpendicular-borders: $size solid transparent;

	@if $direction == top or $direction == bottom {
		border-left:   $perpendicular-borders;
		border-right:  $perpendicular-borders;
	} @else if $direction == right or $direction == left {
		border-bottom: $perpendicular-borders;
		border-top:    $perpendicular-borders;
	}
}

@mixin add_prefix ($key, $value) {
	@each $prefix in $browser_prefix {
		#{$prefix}$key: $value;
	}
}



//==================================================
@mixin font-size($size: 14) {
	font-size: $size + px;
	font-size: ($size / 10)  + rem;
}

@mixin font-sizeRe($size: 14) {
	font-size: $size + px;
}

@mixin setwh($w, $h) {
	height: $h;
	width: $w;
}
//@include setwh(10px, 10px);

@mixin setft($size: 14, $lts: 1, $lhn: 1) {
	font-size: $size + px;
	font-size: ($size / 10)  + rem;
	letter-spacing: $lts;
	line-height: $lhn;
}
@mixin setftRe($size: 14, $lts: 1, $lhn: 1) {
	font-size: $size + px;
	letter-spacing: $lts;
	line-height: $lhn;
}







