@charset 'UTF-8';

.markettransction {
	.main-img {
	    background-image: url(../img/services/markettransction/mainimg.jpg);
	    @include max-screen(767px) {
	    	background-image: url(../img/services/markettransction/mainimg_sp.jpg);
	    }
	}

	.main-content{
		margin: 100px 0 120px;
		@include max-screen(767px) {
			margin: 0 0 80px;
		}
	}

	.markettransction-wrapper{
		margin-bottom: 147px;
		@include max-screen(767px) {
			margin-bottom: 56px;
		}
	}

	.anchor-list{
		li{
			float: left;
			width: 22.3%;
			margin-left: 3.6%;
			@include max-screen(767px) {
				margin: 0;
				width: 50%;

			}
			&:first-child{
				margin-left: 0;
			}

			&:nth-child(even){
				a{						
					@include max-screen(767px) {
						border-left: none;
					}
				}
			}
			&:nth-child(-n+2){
				a{						
					@include max-screen(767px) {
						border-top: 1px solid $color01;
					}
				}
			}
			a{
				font-size: 12px;
				padding: 9px 0 8px;
				@include max-screen(767px) {
					@include setftRe(10,0.08em,21px);
					text-align: left;
					padding-left: 28px;
					border-top: none;
				}
			}
		}
	}

	.sub-title{
		background-color: $color01;
		color: $white;
		@include setftRe(16, 0.08em, 25px);
		text-align: left;
		font-weight: bold;
		padding: 8px 17px 9px;

		&.mt50{
			margin-top: 50px;
			@include max-screen(767px) {
				margin-top: 25px;
			}
		}
	}

	.anchor-block{
		margin-top: 85px;
		@include max-screen(767px) {
			margin-top: 25px;
		}
		.titleCommon{
			margin-bottom: 48px;
			@include max-screen(767px) {
				margin-bottom: 20px;
			}
			span{
				padding-left: 10px;
				@include max-screen(767px) {
					display: none;
				}
			}
		}
		.after-table-note{
			color: $color02;
			@include setftRe(10,0.08em,18px);
			text-align: left;
			margin-top: 30px;
			@include max-screen(767px) {
				margin-top: 10px;
			}
		}

		.table{
			&:nth-of-type(even){
				margin-top: 50px;
				@include max-screen(767px) {
					margin-top: 25px;
				}
			}

			td,
			th{
				vertical-align: middle;
			}

			th{
				@include max-screen(767px) {
					display: none;
				}
			}
			td{
				@include max-screen(767px) {
					display: block;
				}
			}
			.table-heading,
			td{
				p{
					@include max-screen(767px) {
						position: relative;
						display: inline-block;
						letter-spacing: 0;
						padding-left: 15px;
						@include calc-css(width, '100% - 80px');
						vertical-align: middle;
					}
					&:before{
						@include max-screen(767px) {
							position: absolute;
							left: -80px;
							top: 0;
							width: 80px;
							height: 100%;
							content: "";
							background-color: rgba($color03, 0.2);
						}
					}
				}
				&:before{
					@include max-screen(767px) {
						content: attr(data-heading);
						font-weight: bold;
						width: 80px;
						display: inline-block;
						@include setftRe(12, 0em, 18px);
						padding: 0 8px;
						vertical-align: middle;
					}
				}
			}
		}

		&__block01{
			.table{
				th,
				td{
					@include calc-css(width, '100% / 5');
					@include max-screen(767px) {
						width: 100%;
					}
				}
			}
		}

		&__block02{
			.table{
				th,
				td{
					@include calc-css(width, '100% / 3');
					@include max-screen(767px) {
						width: 100%;
					}
				}
				&:nth-of-type(odd){
					margin-top: 50px;
					@include max-screen(767px) {
						margin-top: 25px;
					}
				}
			}
		}

		&__block03{
			.table{
				&:nth-of-type(even){
					margin-top: 72px;
					@include max-screen(767px) {
						margin-top: 35px;
					}
				}
			}

			.table07{
				th,
				td{
					@include calc-css(width, '100% / 4');
					@include max-screen(767px) {
						width: 100%;
					}
				}
			}

			.table08{
				th,
				td{
					@include calc-css(width, '100% / 8');
					@include max-screen(767px) {
						width: 100%;
					}
				}
			}
		}

		&__block04{
			.titleCommon{
				margin-bottom: 30px;
				@include max-screen(767px) {
					margin-bottom: 15px;
				}
			}
			.content-block{
				text-align: left;
				.text{
					@include setftRe(14,0.08em,25px);
					@include max-screen(767px) {
						@include setftRe(12,0.08em,21px);
					}

					span{
						display: block;
						color: $color03;
						font-weight: bold;
					}

				}
			}
		}
	}
}