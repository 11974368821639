@charset "UTF-8";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
	content: "";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
	line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
em {
	font-style: normal;
}
table img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
p {
	margin: 0;
	padding: 0;
}
ol, ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
input, textarea {
	margin: 0;
	font-size: 100%;
	resize: none;
}
input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
}
dl, dt, dd, th, td {
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	/*font-size: 100%;*/
	font-weight: normal;
	margin: 0;
	padding: 0;
}
pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}
*, *:before, *:after {
	@include add_prefix(box-sizing, border-box);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Remove select appearance for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	select::-ms-expand {
		display: none;
	}
}
@media screen\0 {
	select::-ms-expand {
		display: none;
	}
}
html,
body {
	&.noScroll {
		height: 100%;
		overflow: hidden;
	}
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
	color: $color01;
	outline: none;
	@include transition_c(all .5s);
}
a:link {
	text-decoration: none;
}
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
	@include min-screen(768px) {
		opacity: 0.5;
	}
}
a:active {
	text-decoration: none;
}

/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
// .ffGothic{ font-family: "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif; }
.ffGothic{ font-family: "ヒラギノ角ゴ Pro W3", "HiraKakuPro-W4", "HiraKakuPro-W3", "Hiragino Kaku Gothic Pro", "Meiryo", "游ゴシック", "Yu Gothic", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", Arial, Helvetica, sans-serif; }
.ffMincho{ font-family: "游明朝体", "Yu Mincho", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS P明朝", "MS PMincho", serif; }
.ffHiraSTD { font-family: "ヒラギノ角ゴ Std", sans-serif; }
.fHelve { font-family: Helvetica Neue,Helvetica,Arial,sans-serif; }
.ffKozukaGothic { font-family: "小塚ゴシック Pr6N", sans-serif; }


/* =WordPress Core
-------------------------------------------------------------- */

html {
	font-size: 62.5%;
}
body {
	color: $color01;
	font-family: "ヒラギノ角ゴ Pro W3", "HiraKakuPro-W4", "HiraKakuPro-W3", "Hiragino Kaku Gothic Pro", "Meiryo", "游ゴシック", "Yu Gothic", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", Arial, Helvetica, sans-serif;
	line-height: 1;
	letter-spacing: 0;
	margin: 0;
	padding: 0;
	position: relative;
	text-align: center;
	@include transition_c(.5s);
	@include font-size(14);
	@include setwh(100%,100%);
}
/*-----------------------------------------------------------
 Container
------------------------------------------------------------*/
.container {
	max-width: 1160px;
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}
/*-----------------------------------------------------------
 Container
------------------------------------------------------------*/
.container1000 {
	max-width: 1030px;
	margin: 0 auto;
	padding: 0 15px;
	width: 100%;
}

#wrap {
	padding-top: 200px;
	@include max-screen(965px) {
		padding-top: 281px;
	}
	@include max-screen(767px) {
		padding-top: 101px;
	}
}
/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.sp {
	@include min-screen(768px) {
		display: none!important;
	}
}

.pc {
	@include max-screen(767px) {
		display: none!important;
	}
}
img {
	max-width:100%;
	height:auto;
}

/*-----------------------------------------------------------
Text Path
------------------------------------------------------------*/
.txtPath {
	font-feature-settings:"palt";
	-moz-font-feature-settings:"palt";
	-webkit-font-feature-settings:"palt";
}



@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }

}
@media screen\0 {
  select::-ms-expand {
    display: none;
  }
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
}


/*-----------------------------------------------------------
Button Box
------------------------------------------------------------*/
.btnBox {
	display: block;
	font-weight: bold;
	position: relative;
	&::after {
		content: '';
		left: 0;
		position: absolute;
		bottom: 0;
		@include setwh(100%, 5px);
	}
	&.pink {
		background-color: $color03;
		color: $white;
		&::after {
			background-color: $color06;
		}
	}
	&.blueBold {
		background-color: $color05;
		color: $white;
		&::after {
			background-color: $color07;
		}
	}
	&.white {
		background-color: $white;
		color: $color14;
		&::after {
			background-color: $color15;
		}
	}
	&.yellow {
		background-color: $color21;
		color: $white;
		&::after {
			background-color: $color22;
		}
	}
	&.gray{
		background-color: $color27;
		&::after {
			background-color: $color15;
		}
	}
	&.darkGray{
		background-color: $color10;
		color: $white;
		&::after {
			background-color: $color13;
		}
	}
	span {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translate(0,-50%);
		}
		&.icoBank {
			&::before {
				background: url(../img/common/icon/ico_btnBank.svg) left center no-repeat;
				background-size: 100%;
				left: -32px;
				@include setwh(22px,15px);
				@include max-screen(767px) {
					left: -22px;
					width: 18px;
				}
			}
		}
		&.icoText {
			&::before {
				background: url(../img/common/icon/ico_btnText.svg) left center no-repeat;
				background-size: 100%;
				left: -30px;
				@include setwh(17px,18px);
				@include max-screen(767px) {
					left: -20px;
					@include setwh(13px,14px);
				}
			}
		}
		&.icoTextBlue {
			&::before {
				background: url(../img/common/icon/ico_btnText-blue.svg) left center no-repeat;
				background-size: 100%;
				left: -30px;
				@include setwh(17px,18px);
				@include max-screen(767px) {
					left: -28px;
					@include setwh(17px,18px);
				}
			}
		}
		&.icoTextBlack {
			&::before {
				background: url(../img/common/icon/ico_btnTextBlack.svg) left center no-repeat;
				background-size: 100%;
				left: -30px;
				@include setwh(17px,18px);
				@include max-screen(767px) {
					left: -20px;
					@include setwh(13px,14px);
				}
			}
		}

	}
	&.w215 {
		width: 215px;
	}
	&.w262 {
		width: 262px;
		@include max-screen(767px) {
			width: 100%;
		}
	}
}


/* LIST BLOG & NEWS
-------------------------------------------------*/
.top__blockItem__ul {
	.list {
		&__item {
			border-bottom: 2px solid $color16;
			&__imgL {
				float: left;
				width: 110px;
				@include max-screen(767px) {
					width: 100px;
				}
			}
			&__textR, &__textR-noP {
				text-align: justify;
			}
			&__textR {
				padding-left: 128px;
				@include max-screen(767px) {
					padding-left: 110px;
				}
			}
			.textTop {
				margin-bottom: 10px;
				.time {
					display: inline-block;
					font-weight: bold;
					margin-right: 12px;
					vertical-align: top;
					@include setftRe(10,0.03em,17px);
					@include max-screen(767px) {
						display: block;
					}
				}
			}
			&__title {
				font-weight: bold;
				margin-bottom: 5px;
				@include setftRe(16,0.06em,24px);
				@include max-screen(767px) {
					@include setftRe(12,0.06em,18px);
				}
			}
			&__text {
				a,p {
					@include setftRe(14,0.08em,21px);
					@include max-screen(767px) {
						@include setftRe(12,0.08em,18px);
					}
				}
				p {
					display: inline;
					color: $color02;
					position: relative;
				}
				a {
					color: $color03;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}



/* LIST RELATED
-------------------------------------------------*/
.related-block {
	&__main {
		font-size: 0;
		margin: 0 -15px;
		padding: 0 5px;
		@include max-screen(767px) {
			margin: 0;
			padding: 0;
		}
		li {
			display: inline-block;
			padding: 0 15px;
			width: 33.3333%;
			@include max-screen(767px) {
				margin-bottom: 15px;
				padding: 0;
				width: 100%;
			}
			img {
				width: 100%;
			}
		}
	}
}

.full-screen-sp{
	@include max-screen(767px) {
		width: 100%;
		padding: 0;
	}
}






