/*-----------------------------------------------------------
global Navi
-------------------------------------------------------------*/
.nav {
	background-color: $black;
	&__ul {
		font-size: 0;
		padding: 0 10px;
		@include max-screen(767px) {
			padding: 0;
		}
		> li {
			display: inline-block;
			position: relative;
			vertical-align: top;
			width: percentage(125/1130);
			@include max-screen(767px) {
				border-bottom: 1px solid $white;
				text-align: justify;
				width: 100%;
			}
			&::after {
				background-color: $white;
				content: '';
				left: 0;
				position: absolute;
				top: 0;
				@include setwh(1px,100%);
				@include max-screen(767px) {
					width: 0;
				}
			}
			&:last-child {
				&::before {
					background-color: $white;
					content: '';
					right: 0;
					position: absolute;
					top: 0;
					@include setwh(1px,100%);
					@include max-screen(767px) {
						width: 0;
					}
				}
			}
			> a {
				color: $white;
				display: table;
				font-weight: bold;
				padding: 22px 0;
				@include setwh(100%,100%);
				@include setftRe(12,0.08em,21px);
				@include max-screen(767px) {
					padding: 9px 17px;
				}
				&:hover {
					@include min-screen(768px) {
						background-color: $color01;
						opacity: 1;
					}
				}
				.wrap-li {
					display: table-cell;
					vertical-align: middle;
				}

				span {
					color: $color10;
					display: block;
					@include setftRe(9,0.2em,15px);
					@include max-screen(767px) {
						display: inline-block;
						margin-left: 7px;
					}
				}
			}
			&:hover,
			&:focus,
			&:focus-within{
				@include min-screen(768px) {
					.level2{
						visibility: visible;
						opacity: 1;
						z-index: 1;
						@include transform_c(translateY(0%));
						@include transition_c(0.5s);
					}
				}
			}
			.level2{
				position: absolute;
				top: 100%;
				left: 0;
				width: 150%;
				z-index: -1;
				background-color: $black;
				visibility: hidden;
				opacity: 0;
				@include transform_c(translateY(-2em));
				@include transition_c(0.5s);
				@include max-screen(767px) {
					padding-left: 30px;
					position: static;
					visibility: visible;
					opacity: 1;
				}
				li{
					display: block;
					text-align: left;
					@include max-screen(767px) {
						border-top: 1px solid $white;
					}
					&:not(:first-child){
						border-top: 1px solid $white;
					}
					a{
						display: block;
						color: $white;
						font-weight: bold;
						font-size: 12px;
						letter-spacing: 0.05em;
						line-height: 1.5;
						padding: 13px 10px;
						@include max-screen(767px) {
							padding: 8px;
						}
					}
				}
			}
		}
	}
}