@charset 'UTF-8';

/*-----------------------------------------------------------
Footer
------------------------------------------------------------*/
.footer {
	background-color: $color11;
	position: relative;
	&__top {
		font-size: 0;
		margin: 0 -10px;
		padding: 51px 5px 48px;
		text-align: justify;
		@include max-screen(767px) {
			padding: 52px 0 15px;
		}
		.item {
			display: inline-block;
			padding: 0 10px;
			vertical-align: top;
			@include max-screen(767px) {
				margin-bottom: 25px;
			}
			&--w140 {
				width: percentage(160/1130);
				@include max-screen(767px) {
					width: 100%;
				}
			}
			&--w215 {
				width: percentage(235/1130);
				@include max-screen(1129px) {
					width: percentage(260/1130);
				}
				@include max-screen(767px) {
					width: 100%;
				}
			}
			&__top {
				border-bottom: 2px solid $color13;
				padding: 0 0 5px 12px;
				position: relative;
				@include max-screen(767px) {
					padding: 0 0 5px 15px;
				}
				a {
					color: $white;
					font-weight: bold;
					@include setftRe(12,0.08em,21px);
				}
				&::before, &::after {
					background-color: $color03;
					content: '';
					left: 0;
					position: absolute;
					@include setwh(7px,2px);
					@include max-screen(767px) {
						left: 4px;
					}
				}
				&::before {
					top: 8px;
					@include transform_c(rotate(35deg));
				}
				&::after {
					top: 11px;
					@include transform_c(rotate(-35deg));
				}
			}
			&__bottom {
				margin-top: 15px;
				@include max-screen(767px) {
					margin-top: 3px;
					padding-left: 3px;
				}
				li {
					a {
						color: $color12;
						@include setftRe(12,0.13em,24px);
						@include max-screen(767px) {
							line-height: 22px;
						}
					}
				}
			}
		}
	}
	&__btnBox {
		border-top: 1px solid $color12;
		font-size: 0;
		padding: 30px 15px 36px;
		@include max-screen(767px) {
			padding: 18px 15px 15px;
		}
		.btn {
			display: inline-block;
			padding: 0 7px;
			@include max-screen(767px) {
				display: block;
				margin-bottom: 10px;
				padding: 0;
			}
			.btnBox {
			    padding: 16px 0 20px;
				@include setftRe(16,0.08em,28px);
			}
		}
	}
	&__bottom {
		background-color: $color01;
		border-top: 1px solid $color12;
		border-bottom: 1px solid $color12;
		padding: 17px 0;
		ul {
			li {
				display: inline-block;
				padding: 0 20px;
				position: relative;
				&::after {
					background-color: $color12;
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(0,-50%);
					@include setwh(1px,10px);
				}
				&:last-child {
					&::after {
						width: 0;
					}
				}
				a {
					color: $color12;
					@include setftRe(12,0.13em,28px);
					@include max-screen(767px) {
						line-height: 23px;
					}
				}
			}
		}
	}
	&__coppyRight {
		color: $color16;
		font-weight: bold;
		padding: 25px 0;
		@include setftRe(10,0.13em,18px);
		@include max-screen(767px) {
			padding: 22px 0;
		}
	}
	.btn-back {
		cursor: pointer;
	    position: fixed;
	    right: 40px;
	    bottom: 40px;
	    z-index: 9999;
		@include max-screen(767px) {
			width: 45px;
			right: 15px;
			bottom: 35px;
		}
	}
}