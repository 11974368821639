@charset "UTF-8";
@import "media-queries";
@import "mixin";

/*-----------------------------------------------------------
components
------------------------------------------------------------*/
.icon{
	vertical-align: middle;
}

.left{
	float: left;
	@include max-screen(767px) {
		float: none;
	}
}

.right{
	float: right;
	@include max-screen(767px) {
		float: none;
	}
}


/* BUTTON MORE
-------------------------------------------------*/
.btnMore {
	border: 1px solid $white;
	color: $white;
	font-weight: bold;
	max-width: 148px;
	margin: 0 auto;
	padding: 3px 0;
	width: 100%;
	@include setftRe(15,0.08em,26px);
	@include max-screen(767px) {
		max-width: 72px;
		padding: 1px 0;
		@include setftRe(7,0.08em,13px);
	}
}
@media screen\0{
	.btnMore {
		padding: 5px 0 3px;
	}
}



/* BACKGROUND COLOR
-------------------------------------------------*/
.bgColor {
	background-color: rgba(8, 8, 11, 0.2);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	@include setwh(100%,100%);
}



/* BUTTON EFFECT
-------------------------------------------------*/
.buttonEffect {
	position: relative;
	z-index: 2;
	&::after, &::before {
		background-color: rgba(8, 8, 11, 0.5);
		content: '';
		display: block;
		position: absolute;
		top: 0;
		z-index: -1;
		@include transition_c(all .5s);
		@include setwh(50%,100%);
	}
	&::before {
		right: 0;
	}
	&::after {
		left: 0;
	}
	&:hover {
		background-color: rgba(8, 8, 11, 0);
		&::after, &::before {
			background-color: rgba(8, 8, 11, 0.5);
			width: 0;
		}
	}
}





/* TITLE background
-------------------------------------------------*/
.titleBground {
	background-color: $color19;
	border-bottom: 2px solid $color03;
	font-weight: bold;
	padding: 5px 15px 8px;
	text-align: justify;
	@include setftRe(16,0.08em,28px);
	@include max-screen(767px) {
		padding: 9px 15px 5px;
		text-align: center;
	}
}


/* ICO CATE
-------------------------------------------------*/
.icoCate {
	color: $white;
	font-weight: bold;
	margin-right: 9px;
	padding: 5px 11px;
	@include setftRe(10,0,17px);
	@include max-screen(767px) {
		margin-right: 4px;
		padding: 3px 7px;
		@include setftRe(9,0,16px);
	}
	&--01 {
		background-color: $color03;
	}
	&--02 {
		background-color: $color17;
	}
	&--03 {
		background-color: $color18;
	}
}



/* BUTTON ADD LINK
-------------------------------------------------*/
.btnAdd-link {
	border: 1px solid $color01;
	display: block;
	font-weight: bold;
	padding: 10px 0;
	text-align: center;
	@include setftRe(14,0.08em,21px);
	&--arrowL {
		background: url(../img/common/icon/ico_arrowL.svg) 20px center no-repeat;
		background-size: 7px 10px;
		@include max-screen(767px) {
			background-position: 15px center;
		}
	}
}


/* SELECT RESET
-------------------------------------------------*/
.selectTrading {
	border: 1px solid $color01;
	background: $white url(../img/common/icon/ico_select.png) right top no-repeat;
	background-size: 35px 35px;
	padding: 0 10px;
	position: relative;
	@include setwh(275px,35px);
	@include border-radius(0);
	@include max-screen(1024px) {
		background: url(../img/common/icon/ico_select.png) right -2px no-repeat;
		background-size: 35px 35px;
	}
	@include max-screen(767px) {
		background-size: 33px 33px;
		@include setwh(100%,33px);
	}
}


/* MAIN IMAGES PAGE_TOP
-------------------------------------------------*/
.main-img {
	height: 202px;
	background-position: center;
	background-size: cover;
	position: relative;
	@include max-screen(767px) {
		height: 124px;
	}
	.page-name-box {
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 52%;
		text-align: center;
		@include transform_c(translate(0, -50%));
		.jpn, .eng {
			font-weight: bold;
		}
		.jpn {
			color: $white;
			@include setftRe(28,0.08em,32px);
			@include max-screen(767px) {
				@include setftRe(18,0.08em,24px);
			}
		}
		.eng {
			color: $color03;
			padding-top: 10px;
			@include setftRe(12,0.13em,32px);
			@include max-screen(767px) {
				padding-top: 5px;
				@include setftRe(8,0.13em,24px);
			}
		}
	}
}



/* TITLE COMMON
-------------------------------------------------*/
.titleCommon {
	color: $color01;
	font-weight: bold;
	position: relative;
	padding-bottom: 9px;
	text-align: justify;
	@include max-screen(767px) {
		padding-bottom: 12px;
	}
	&.fZize28 {
		@include setftRe(28,0.08em,49px);
		@include max-screen(767px) {
			@include setftRe(16,0.08em,28px);
		}
	}
	&.fZize24 {
		@include setftRe(24,0.08em,42px);
		@include max-screen(767px) {
			@include setftRe(16,0.08em,28px);
		}
	}
	&.fZize18 {
		@include setftRe(18,0.06em,24px);
	}
	&::after, &::before {
		content: '';
		left: 0;
		bottom: 0;
		position: absolute;
	}
	&::before {
		background-color: $color16;
		@include setwh(100%,4px);
	}
	&::after {
		background-color: $color03;
		@include setwh(100px,4px);
	}
	span {
		color: $color16;
		vertical-align: middle;
		padding-left: 3px;
		@include setftRe(10,0.08em,17px);
		@include max-screen(767px) {
			padding-left: 2px;
		}
	}
}



/* SUB TITLE
-------------------------------------------------*/
.sub-title{
	background-color: $color01;
	color: $white;
	@include setftRe(16, 0.08em, 25px);
	text-align: left;
	font-weight: bold;
	padding: 8px 17px 9px;
}

/* TABLE COMPONENTS
-------------------------------------------------*/
.table-cover{
	overflow-x: auto;
	@include max-screen(767px){
		overflow-x: hidden;
	}
}
.table{
	width: 100%;
	border: 1px solid $color01;
	@include max-screen(767px) {
		border: none;
	}
	tr{
		&:last-child{
			td:last-child{
				@include max-screen(767px) {
					border-bottom: 1px solid $color02;
				}
			}
		}
	}
	th,
	td{
		border: 1px solid $color02;
		@include max-screen(767px) {
			border: none;
			border-top: 1px solid $color02;
			text-align: left;
		}
	}
	th,
	.table-heading{
		font-weight: bold;
		p{
			@include setftRe(14, 0.08em, 25px);
			padding: 10px 1px 6px;
		}
	}
	th{
		background-color: rgba($color03, 0.2);
	}
	td{
		p{
			@include setftRe(12, 0.05em, 18px);
			padding: 12px 1px 11px;
		}
	}
}

/* lb768px LINE BREAK ONLY ON 768PX
-------------------------------------------------*/
.lb768px{
	display: none;
	@include screen(768px, 1023px){
		display: initial;
	}
}


/* SEARCH'S WORD TITLE
-------------------------------------------------*/
.search-word{
	text-align: left;
	@include setftRe(32,0.06em,38px);
	font-weight: bold;
	margin-bottom: 25px;
	@include max-screen(767px) {
		@include setftRe(21,0.06em,38px);
		margin-bottom: 10px;
	}
}









