@charset 'UTF-8';

.sakura-seminar-report {
	.main-img {
		background-image: url(../img/sakura-seminar-report/mainimg.jpg);
		@include max-screen(767px) {
			background-image: url(../img/sakura-seminar-report/mainimgsp.jpg);
		}
	}
	.main-content {
		padding: 65px 0 140px;
		@include max-screen(767px) {
			padding: 30px 0 65px;
		}
	}
	.related-block {
		padding-bottom: 145px;
		@include max-screen(767px) {
			padding-bottom: 40px;
		}
	}
	.contentL {
		float: left;
		padding-top: 3px;
		width: 66%;
		@include max-screen(767px) {
			float: none;
			padding-top: 0;
			width: 100%;
		}
		ul {
			li {
				border-top: 2px solid $color16;
				display: table;
				padding: 16px 3px 19px;
				width: 100%;
				@include max-screen(767px) {
					display: block;
					padding: 18px 0 25px;
				}
				&:last-child {
					border-bottom: 2px solid $color16;
				}
			}
		}
		&__left {
			text-align: justify;
			.time {
				@include setftRe(10,0.08em,18px);
			}
			.title {
				font-weight: bold;
				margin: 2px 0 4px;
				@include setftRe(16,0.06em,24px);
				@include max-screen(767px) {
					@include setftRe(14,0.06em,21px);
					margin: 5px 0;
				}

				// PREPARE SEARCH MATCH
				// .search-match{
				// 	background-color: rgba($color03, 0.3);
				// }
			}
			.lecturer {
				color: $color02;
				@include setftRe(14,0.08em,21px);
				@include max-screen(767px) {
					@include setftRe(12,0.08em,21px);
				}
				span {
					display: inline-block;
					padding-right: 10px;
					position: relative;
					vertical-align: top;
					&::after {
						content: '/';
						position: absolute;
						right: -2px;
					}
					&:last-child {
						&::after {
							content: '';
						}
					}
				}
			}
		}
		&__right {
			display: table-cell;
			vertical-align: top;
			width: 215px;
			@include max-screen(767px) {
				display: block;
				margin-top: 11px;
				width: 100%;
			}
			
		}
		.btnPost {
			color: $white;
			background-color: $color03;
			display: block;
			float: right;
			font-weight: bold;
			margin-top: 6px;
			padding: 7px 0;
			width: 175px;
			@include setftRe(12,0.08em,21px);
			@include max-screen(767px) {
				float: none;
				width: 100%;
			}
			&.pdfB {
				span {
					position: relative;
					&::before {
						background: url(../img/sakura-seminar-report/ico_pdf.svg) no-repeat;
						content: '';
						left: -20px;
						top: 2px;
						position: absolute;
						@include setwh(10px,10px);
						@include max-screen(1024px) {
							background-size: 100%;
						}
					}
				}
			}
		}
	}
	.slidebar {
		float: right;
		padding-left: 30px;
		width: 34%;
		@include max-screen(767px) {
			float: none;
			padding-left: 0;
			width: 100%;
			margin-top: 40px;
		}
		.slidebarList {
			margin-bottom: 60px;
			@include max-screen(767px) {
				margin-bottom: 45px;
			}
		}
		&__search {
			.searchPost {
				margin-top: 25px;
			}
			.btn-search {
				position: relative;
				input {
					border: 1px solid $color16;
					padding-left: 5px;
					padding-right: 40px;
					border-radius: 0px;
					@include setwh(100%,34px);
					@include max-screen(767px) {
						height: 25px;
					}
				}
				.btnSubmit {
					background-color: transparent;
					padding: 0;
					border: none;
					cursor: pointer;
					position: absolute;
					top: 0;
					right: 0;
					@include setwh(40px,100%);
					i {
						position: absolute;
						top: 0;
						right: 0;
						display: block;
						@include setwh(40px,100%);
						&::after, &::before {
							content: '';
							position: absolute;
						}
						&::before {
							border-radius: 50%;
							border: 2px solid $color03;
							right: 15px;
							top: 9px;
							@include setwh(13px,13px);
							@include max-screen(767px) {
								top: 4px;
								right: 13px;
							}
						}
						&::after {
							background-color: $color03;
							@include transform_c(rotate(-45deg));
							bottom: 7px;
							right: 14px;
							@include setwh(2px,9px);
							@include max-screen(767px) {
								bottom: 4px;
								right: 12px;
							}
						}
					}
				}
			}
		}
		&__related {
			text-align: justify;
			ul {
				li {
					border-bottom: 1px solid $color16;
					margin-bottom: 5px;
					padding: 14px 0 15px;
					@include max-screen(767px) {
						margin-bottom: 0;
						padding: 14px 6px 15px;
					}
					p {
						@include setftRe(10,0.08em,18px);
					}
					a{
						display: block;
					}
					div {
						font-weight: bold;
						@include setftRe(14,0.05em,24px);
						@include max-screen(767px) {
							line-height: 21px;
							margin-top: 5px;
						}
					}
				}
			}
		}
		&__year {
			text-align: justify;
			padding-top: 15px;
			@include max-screen(767px) {
				padding-top: 9px;
			}
			.menuAr {
				margin-top: 25px;
				@include max-screen(767px) {
					margin-top: 15px;
				}
				>li {
					padding-left: 15px;
					position: relative;
					@include setftRe(14,0.05em,28px);
					&::after {
						background: url(../img/common/icon/ico_year.svg) no-repeat;
						content: '';
						left: 3px;
						position: absolute;
						top: 7px;
						@include setwh(9px,12px);
					}
					>ul {
						display: none;
						padding-left: 15px;
					}
				}
			}
		}
	}
	.wp-pagenavi {
		margin-top: 50px;
		@include max-screen(767px) {
			margin: 30px 0 113px;
		}
		.pages{
			display: none;
		}
		.previouspostslink, .nextpostslink {
			border: 2px solid $black;
			display: inline-block;
			position: relative;
			@include setwh(30px,40px);
			&::before {
				border-top: 4.75px solid transparent;
				border-bottom: 4.75px solid transparent;
				content: '';
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
			}
		}
		.previouspostslink {
			margin-right: 25px;
			@include max-screen(767px) {
				margin-right: 25px;
			}
			&::before {
				border-right: 6.5px solid $black;
			}
		}
		.nextpostslink {
			margin-left: 25px;
			@include max-screen(767px) {
				margin-left: 25px;
			}
			&::before {
				border-left: 6.5px solid $black;
			}
		}
		a, span {
			color: $black;
			font-weight: bold;
			padding: 0 8px;
			vertical-align: middle;
			@include font-sizeRe(14);
			@include max-screen(767px) {
				padding: 0 7px;
				@include font-sizeRe(12);
			}
		}
	}
	&.detail {
		.reportDetail {
			padding-right: 20px;
			text-align: justify;
			@include max-screen(767px) {
				padding-right: 0;
			}
			.time {
				font-weight: 600;
				@include setftRe(10,0.08em,18px);
			}
			.title {
				font-weight: bold;
				@include setftRe(22,0.06em,35px);
				@include max-screen(767px) {
					@include setftRe(16,0.06em,24px);
				}
			}
			.lecturer {
				color: $color02;
				@include setftRe(14,0.08em,21px);
				@include max-screen(767px) {
					@include setftRe(12,0.08em,21px);
				}
				span {
					padding-right: 10px;
					position: relative;
					&::after {
						content: '/';
						position: absolute;
						right: -2px;
					}
					&:last-child {
						&::after {
							content: '';
						}
					}
				}
			}
		}
		.contentText {
			margin-top: 35px;
			padding-bottom: 75px;
			@include max-screen(767px) {
				padding-bottom: 33px;
				margin-top: 20px;
			}
			p {
				margin-bottom: 42px;
				@include setftRe(14,0.08em,21px);
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.relatedPost {
			border-top: 2px solid $color16;
			padding-top: 25px;
			position: relative;
			@include max-screen(767px) {
				margin-bottom: 105px;
				padding-top: 18px;
			}
			&__prev {
				float: left;
			}
			&__next {
				float: right;
			}
			&__prev, &__next {
				a {
					border: 2px solid $black;
					display: block;
					font-weight: bold;
					padding: 8px 0;
					width: 90px;
					@include setftRe(10,0.08em,21px);
					@include max-screen(767px) {
						width: 77px;
						padding: 2px 0;
					}
				}
			}
			.backList {
				font-weight: bold;
				left: 50%;
				position: absolute;
				transform: translate(-50%,-50%);
				top: 71%;
				@include setftRe(14,0.08em,21px);
				@include max-screen(767px) {
					font-size: 12px;
				}
			}
		}
		.related-block {
			padding-top: 43px;
			@include max-screen(767px) {
				padding-top: 0;
			}
		}
		.btnPost{
			float: none;
			text-align: center;
			margin: 0 auto;

			width: 300px;
		}
	}
}















